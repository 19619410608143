import { IconButton, Tooltip } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import { Delete, Edit } from "@material-ui/icons";
import React, { useState } from "react";
import { QueryResponse } from "react-fetching-library";
import { theme } from "../../constants/theme";
import { Customer, CustomerPost } from "../../validations/entities";
import ConfirmDialog from "../common/ConfirmDialog";
import QueryDataGrid from "../common/QueryDataGrid";

interface CustomerListProps {
  loading: boolean;
  error: boolean;
  onReload: () => Promise<QueryResponse<Customer[]>>;
  customers?: Customer[];
  deleteCustomer: (customerId: string) => void;
  updateCustomer: React.Dispatch<
    React.SetStateAction<Omit<Partial<CustomerPost>, "ObjectId"> | undefined>
  >;
}

const CustomerList = ({
  loading,
  error,
  onReload,
  customers,
  deleteCustomer,
  updateCustomer,
}: CustomerListProps) => {
  const [customerId, setCustomerId] = useState<string>();
  const initiateDelete = (customerId: string) => {
    setCustomerId(customerId);
  };
  const cancelDelete = () => {
    setCustomerId(undefined);
  };
  const handleDelete = (customerId: string) => {
    deleteCustomer(customerId);
    setCustomerId(undefined);
  };

  const columns: GridColDef[] = [
    {
      field: "FirstName",
      headerName: "First Name",
      flex: 0.25,
    },
    { field: "LastName", headerName: "Last Name", flex: 0.25 },
    {
      field: "LegacyCustomerId",
      headerName: "Legacy Customer Id",
      flex: 0.25,
    },
    {
      field: "CustomerId",
      renderHeader: () => {
        return <></>;
      },
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const value = params.value;
        const updateObject = customers?.find(
          (customer) => customer.CustomerId === value,
        ) as Omit<Customer, "CreatedOn" | "UpdatedOn">;
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() =>
                  updateCustomer({
                    Accounts:
                      updateObject.Accounts?.map((acct) => {
                      return `${acct.accountId};${acct.accountNum}`;
                    }),
                    CustomerId:
                      updateObject.CustomerId === null
                        ? undefined
                        : updateObject.CustomerId,
                    FirstName:
                      updateObject.FirstName === null
                        ? undefined
                        : updateObject.FirstName,
                    LastName:
                      updateObject.LastName === null
                        ? undefined
                        : updateObject.LastName,
                    ScannerId:
                      updateObject.ScannerId === null
                        ? undefined
                        : updateObject.ScannerId,
                    LegacyCustomerId:
                      updateObject.LegacyCustomerId === null
                        ? undefined
                        : updateObject.LegacyCustomerId,
                  })
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                style={{ color: theme.palette.error.main }}
                onClick={() => initiateDelete(value ? value.toString() : "")}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      flex: 0.15,
    },
  ];

  return (
    <>
      {customerId && (
        <ConfirmDialog
          open={true}
          message={`Are you sure you want to delete this customer?`}
          onConfirm={() => handleDelete(customerId)}
          onDismiss={() => cancelDelete()}
        />
      )}
      <QueryDataGrid
        columns={columns}
        loading={loading}
        error={error}
        onReload={onReload}
        payload={customers}
      />
    </>
  );
};

export default CustomerList;
