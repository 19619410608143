import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React from "react";
import { QueryResponse } from "react-fetching-library";

interface FormDialogProps {
  loading: boolean;
  loadingMessage: string;
  error: boolean;
  errorMessage?: string;
  success: boolean;
  successMessage?: string;
  onReload: (action: any) => Promise<QueryResponse<any>>;
  onClose: (args0: any) => void;
}

const FormDialog = ({
  loading,
  loadingMessage,
  error,
  errorMessage,
  success,
  successMessage,
  onReload,
  onClose,
}: FormDialogProps) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {loading && loadingMessage}
        {error && "Error Occurred."}
        {success && "Success!"}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {error && errorMessage && errorMessage}
          {success && successMessage && successMessage}
        </Typography>
        {loading && <LinearProgress color="secondary" />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        {error && (
          <Button color="secondary" onClick={() => onReload}>
            Try Again
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
