import {
  DataGrid as MuiDataGrid,
  DataGridProps,
  GridOverlay,
} from "@material-ui/data-grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: 0,
      "& .MuiDataGrid-toolbar .MuiButtonBase-root": {
        color: theme.palette.secondary.main,
      },
      "& .MuiDataGrid-row": {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.active,
        },
      },
      "& .MuiDataGrid-columnsContainer, .MuiIconButton-root": {
        color: theme.palette.text.secondary,
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-colCell": {},
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color: theme.palette.primary.main,
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      "&  .MuiTablePagination-toolbar, .MuiToolbar-root": {
        minHeight: 0,
        height: "auto",
      },
    },
    cell: {
      color: theme.palette.primary.main,
    },
  }),
);

export const DataGrid = (props: DataGridProps) => {
  const classes = useStyles();
  return (
    <MuiDataGrid
      density="compact"
      disableSelectionOnClick
      {...props}
      className={classes.root}
    />
  );
};

export const ErrorOverlay = ({ children }: { children?: any }) => {
  return (
    <GridOverlay>
      <Box
        width={1}
        height={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography>There was a problem getting the records</Typography>
        {children}
      </Box>
    </GridOverlay>
  );
};
