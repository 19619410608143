import Jimp from "jimp";
import { ContextType } from "react";
import { ClientContext } from "react-fetching-library";
import { uploadCheckAction } from "./cpaService";

/** Handles uploading the front and back images of the checks to the storage account.
 * Returns the paths, and the jpeg image.
 * */
export const uploadCheckAndConvertToJpeg = async (
  base: string,
  customerId: string,
  name: string,
  context: ContextType<typeof ClientContext>,
) => {
  const img = await Jimp.read(`data:image/tiff;base64,${base}`)
    .catch((err) => console.log(err))
    .then((image) => {
      return image;
    });
  if (img) {
    const tiffBase = await img.getBase64Async("image/tiff");
    const tiffFile: File | undefined = dataURLtoFile(
      `data:image/tiff;base64,${base}`,
      name,
      "image/tiff",
    );
    if (!tiffFile) {
      return [undefined, undefined];
    }
    const formData = new FormData();
    formData.append("CustomerId", customerId);
    formData.append("File", tiffFile);
    // upload the file to blob storage
    const { error, status, payload } = await context.query(
      uploadCheckAction(formData),
    );
    if (error) {
      return [undefined, undefined];
    }
    if (status === 200 && payload) {
      if (
        payload !==
        "Index was out of range. Must be non-negative and less than the size of the collection. (Parameter 'index')"
      ) {
        const imageBase = await img.getBase64Async("image/jpeg");
        return [payload, imageBase];
      }
    }
  }
  return [undefined, undefined];
};

export function dataURLtoFile(dataurl: string, filename: string, mime: string) {
  var arr: string[] = dataurl.split(",");

  if (arr[0] && arr[0] !== null && arr[1] && arr[1] !== null) {
    var bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  return undefined;
}
