import { Box } from "@material-ui/core";
import React from "react";
import { Query, useMutation } from "react-fetching-library";
import {
  addScannerAction,
  deleteScannerAction,
  getScannersAction,
  updateScannerAction,
} from "../../api/endpoints/cpaService";
import ScannerForm from "../../components/admin/ScannerForm";
import ScannerList from "../../components/admin/ScannerList";
import { theme } from "../../constants/theme";
import { ScannerPost } from "../../validations/entities";

const Scanners = () => {
  const [updateScanner, setUpdateScanner] = React.useState<ScannerPost>();
  const addScannerQuery = useMutation(addScannerAction);
  const deleteScannerQuery = useMutation(deleteScannerAction);
  const updateScannerQuery = useMutation(updateScannerAction);

  return (
    <Query action={getScannersAction()} initFetch={true}>
      {({ loading, error, payload, query }) => (
        <>
          <Box
            width={1 / 2}
            borderRight={`1px solid ${theme.palette.divider}`}
            px={3}
            pt={3}
          >
            <ScannerForm
              defaultValues={updateScanner}
              postScannerQuery={
                updateScanner ? updateScannerQuery : addScannerQuery
              }
              onSuccess={
                updateScanner
                  ? () => {
                      setUpdateScanner(undefined);
                      query();
                    }
                  : query
              }
              onCancel={
                updateScanner
                  ? () => {
                      setUpdateScanner(undefined);
                    }
                  : undefined
              }
            />
          </Box>
          <Box width={1 / 2} borderRight={`1px solid ${theme.palette.divider}`}>
            <ScannerList
              loading={loading}
              error={error}
              scanners={payload}
              onReload={query}
              deleteScanner={(scannerId: string) => {
                deleteScannerQuery.mutate({ ScannerId: scannerId }).then(() => {
                  query();
                });
              }}
              updateScanner={setUpdateScanner}
            />
          </Box>
        </>
      )}
    </Query>
  );
};

export default Scanners;
