import React, { useContext } from "react";
import { BatchPost } from "../../validations/entities";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { BandedTableCellLessPadding } from "../brand/Tables";
import { theme } from "../../constants/theme";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticationAccountIdentifier } from "../../redux/slices/authenticationSlice";
import CheckListItem from "./CheckListItem";
import { ClientContext, QueryResponse } from "react-fetching-library";
import FormDialog from "../common/FormDialog";
import {
  CLEAR_CHECKS,
  DELETE_CHECK,
  selectChecks,
  SET_CHECK_ACCOUNT,
  SET_CHECK_AMOUNT,
  SET_CHECK_MICR,
  SET_CHECK_NUMBER,
  SET_CHECK_PAYOR,
} from "../../redux/slices/checksSlice";
import { handleScan, selectScanner } from "../../redux/slices/rdmSlice";
import { Scanner } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { selectCustomerScanner } from "../../redux/slices/configSlice";
import {
  invalidAlphanumeric,
  isAlphanumeric,
  requiredMessage,
} from "../../validations/helpers";

interface BatchFormProps {
  postBatchQuery: {
    loading: boolean;
    error: boolean;
    status?: number;
    mutate: (action: BatchPost) => Promise<QueryResponse<string>>;
  };
  onSuccess: () => void;
}

const BatchForm = ({ onSuccess, postBatchQuery }: BatchFormProps) => {
  const history = useHistory();
  const [data, setData] = React.useState<BatchPost>();
  const client = useContext(ClientContext);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const identity = useSelector(selectAuthenticationAccountIdentifier);
  const userId = identity ? identity : "";
  const customerScanner = useSelector(selectCustomerScanner);
  const scannerId =
    customerScanner && customerScanner?.scannerId
      ? customerScanner.scannerId
      : "";
  const scannerModel =
    customerScanner && customerScanner?.modelNumber
      ? customerScanner.modelNumber
      : "";
  const scannerSerialNumber =
    customerScanner && customerScanner?.serialNumber
      ? customerScanner.serialNumber
      : "";
  const scanner = useSelector(selectScanner);
  const checks = useSelector(selectChecks);
  const methods = useForm({});
  const indChecks = Object.keys(checks).map((v, i) => {
    const check = Object.values(checks)[i];
    return {
      ...check,
    };
  });

  const handleCloseFormDialog = (error: boolean) => {
    setData(undefined);
    if (!error) {
      methods.reset({
        batchName: "",
        batchDescription: "",
        checks: undefined,
      });
    }
  };

  const onSubmit = (data: any) => {
    const Checks = Object.keys(checks).map((k, i) => {
      const check = Object.values(checks)[i];
      return {
        AccountNum: check.accountNumber ? check.accountNumber : "",
        Amount: check.amount ? Number(check.amount) : 0.0,
        FrontPath: check.front?.path ? check.front.path : "",
        BackPath: check.back?.path ? check.back.path : "",
        CheckNumber: check.number ? check.number : "",
        MICR: check.micr ? check.micr : "",
        Payor: check.payor ? check.payor : "",
      };
    });
    const postBatch: BatchPost = {
      BatchName: data.batchName,
      BatchDescription: data.batchDescription,
      CustomerId: userId,
      NoOfChecks: Checks.length,
      ScannerId: scannerId,
      ScannerModel: scannerModel,
      ScannerSerialNumber: scannerSerialNumber,
      Amount: Checks.map((v) => v.Amount).reduce((p, c) => {
        return p + c;
      }),
      Checks: Checks,
      CreatedBy: userId,
    };
    setData(postBatch);
    postBatchQuery.mutate(postBatch).then((res) => {
      if (res.status === 200) {
        onSuccess();
      }
    });
  };

  React.useEffect(() => {
    // handle the scanner errors
    if (scanner.error) {
      enqueueSnackbar(scanner.error, {
        variant: "error",
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, scanner.error]);

  React.useEffect(() => {
    // only once per render to prevent multiple calls
    if (indChecks) {
      if (indChecks.length > 0) {
        for (let ind = 0; ind < indChecks.length; ind++) {
          const check = indChecks[ind];
          if (check) {
            if (check.front) {
              if (check.front.error) {
                enqueueSnackbar(check.front.error, {
                  variant: "error",
                  preventDuplicate: true,
                });
              }
            }
            if (check.back) {
              if (check.back.error) {
                enqueueSnackbar(check.back.error, {
                  variant: "error",
                  preventDuplicate: true,
                });
              }
            }
          }
        }
      }
    }
  }, [enqueueSnackbar, indChecks]);

  return (
    <>
      {data && (
        <FormDialog
          error={postBatchQuery.error}
          success={postBatchQuery.status === 200}
          loading={postBatchQuery.loading}
          loadingMessage={`Creating Batch...`}
          onClose={() => handleCloseFormDialog(postBatchQuery.error)}
          onReload={() => postBatchQuery.mutate(data)}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TextField
            name="batchName"
            id="batchName"
            variant="filled"
            label="Batch Name"
            margin="normal"
            inputRef={methods.register({
              required: requiredMessage,
              maxLength: 50,
              pattern: {
                value: isAlphanumeric,
                message: invalidAlphanumeric,
              },
            })}
            error={methods.errors?.batchName}
            helperText={methods.errors?.batchName?.message}
            fullWidth
          />
          <TextField
            name="batchDescription"
            id="batchId"
            variant="filled"
            label="Batch Description"
            margin="normal"
            inputRef={methods.register({
              required: requiredMessage,
              maxLength: 50,
              pattern: {
                value: isAlphanumeric,
                message: invalidAlphanumeric,
              },
            })}
            error={methods.errors?.batchDescription}
            helperText={methods.errors?.batchDescription?.message}
            fullWidth
          />

          <Box my={3}>
            <Box
              bgcolor="info.light"
              border={1}
              style={{
                borderColor: theme.palette.divider,
                borderBottomColor: "transparent",
                borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
              }}
              display="flex"
              justifyContent="space-between"
              py={2}
              px={3}
              width={1}
            >
              <Typography variant="overline" color="secondary">
                Checks
              </Typography>

              <Button
                disableElevation
                endIcon={
                  scanner.scanning ? (
                    <CircularProgress size="small" color="inherit" />
                  ) : (
                    <Scanner />
                  )
                }
                color="secondary"
                variant="contained"
                disabled={scanner.scanning}
                onClick={() =>
                  dispatch(
                    handleScan({
                      clientContext: client,
                      userId,
                    }),
                  )
                }
              >
                {scanner.scanning ? "Scanning..." : "Scan Check"}
              </Button>
            </Box>
            <Box
              border={1}
              style={{
                borderColor: theme.palette.divider,
                borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px `,
              }}
              width={1}
              display="flex"
              flexDirection="column"
            >
              <TableContainer style={{ overflow: "hidden" }}>
                {checks && Object.keys(checks).length > 0 ? (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <BandedTableCellLessPadding width={25}>
                          SI.
                        </BandedTableCellLessPadding>
                        <BandedTableCellLessPadding width={225}>
                          MICR
                        </BandedTableCellLessPadding>
                        <BandedTableCellLessPadding width={125}>
                          Amount (USD)
                        </BandedTableCellLessPadding>
                        <BandedTableCellLessPadding>
                          Payor
                        </BandedTableCellLessPadding>
                        <BandedTableCellLessPadding width={125}>
                          Check No.
                        </BandedTableCellLessPadding>
                        <BandedTableCellLessPadding>
                          Deposit to
                        </BandedTableCellLessPadding>
                        <BandedTableCellLessPadding
                          width={25}
                          padding="checkbox"
                        />
                        <BandedTableCellLessPadding
                          width={25}
                          padding="checkbox"
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(checks).map((key, index) => {
                        const check = Object.values(checks)[index];
                        return (
                          <CheckListItem
                            check={check}
                            id={key}
                            index={index}
                            setAccount={(v: string) =>
                              dispatch(
                                SET_CHECK_ACCOUNT({ id: key, account: v }),
                              )
                            }
                            setAmount={(v: string) =>
                              dispatch(SET_CHECK_AMOUNT({ id: key, amount: v }))
                            }
                            setNumber={(v: string) =>
                              dispatch(SET_CHECK_NUMBER({ id: key, number: v }))
                            }
                            setPayor={(v: string) =>
                              dispatch(SET_CHECK_PAYOR({ id: key, payor: v }))
                            }
                            setMicr={(v: string) =>
                              dispatch(SET_CHECK_MICR({ id: key, micr: v }))
                            }
                            remove={() => dispatch(DELETE_CHECK(key))}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <Box width={1} p={3}>
                    {methods.errors?.checks ? (
                      <Typography
                        variant="body2"
                        style={{ color: theme.palette.error.main }}
                        align="center"
                      >
                        At least one check is required for a batch to be
                        submitted.
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        No checks have been scanned.
                      </Typography>
                    )}
                  </Box>
                )}
              </TableContainer>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box width={1 / 3} mr={3}>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(CLEAR_CHECKS());
                  history.push("/");
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box width={1 / 3}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                color="secondary"
                disableElevation
                fullWidth
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default BatchForm;
