import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addBatchAction } from "../../api/endpoints/cpaService";
import BatchForm from "../../components/customer/BatchForm";
import { CLEAR_CHECKS } from "../../redux/slices/checksSlice";

const Processing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const postBatchQuery = useMutation(addBatchAction);
  const onSuccess = () => {
    dispatch(CLEAR_CHECKS());
    history.push("/");
  };
  return (
    <Box mt={3}>
      <Typography variant="subtitle1" gutterBottom>
        Upload Batch
      </Typography>
      <Divider />
      <Box mt={3}>
        <Typography variant="overline" color="secondary">
          Batch details
        </Typography>
      </Box>
      <BatchForm
        postBatchQuery={postBatchQuery}
        onSuccess={() => onSuccess()}
      />
    </Box>
  );
};

export default Processing;
