import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField, Typography } from "@material-ui/core";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { theme } from "../../constants/theme";
import FormActionsContainer from "../../containers/FormActionsContainer";
import { Scanner, ScannerPost } from "../../validations/entities";
import { SCANNER_SCHEMA } from "../../validations/schemas";
import { PrimaryButton, CancelButton } from "../brand/Buttons";
import FormDialog from "../common/FormDialog";
import ScannerModelSelect from "./ScannerModelSelect";

interface ScannerFormProps {
  postScannerQuery: {
    loading: boolean;
    error: boolean;
    status?: number;
    mutate: (action: ScannerPost) => Promise<QueryResponse<Scanner>>;
  };
  onSuccess: () => void;
  defaultValues?: ScannerPost;
  onCancel?: () => void;
}

const ScannerForm = React.memo(
  ({
    postScannerQuery,
    onSuccess,
    defaultValues,
    onCancel,
  }: ScannerFormProps) => {
    const [data, setData] = React.useState<ScannerPost>();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const methods = useForm({
      resolver: yupResolver(SCANNER_SCHEMA),
      defaultValues: {
        ModelNumber: "",
        SerialNumber: "",
      },
    });
    const handleCloseFormDialog = (error: boolean) => {
      setData(undefined);
      if (!error) {
        methods.reset({
          ModelNumber: "",
          SerialNumber: "",
        });
      }
    };
    const onSubmit = (data: any) => {
      const newScanner: ScannerPost = {
        ScannerId: defaultValues?.ScannerId,
        ModelNumber: data.ModelNumber,
        SerialNumber: data.SerialNumber,
      };
      setData(newScanner);
      postScannerQuery.mutate(newScanner).then((res) => {
        if (res.status === 200) {
          onSuccess();
        }
      });
    };

    React.useEffect(() => {
      if (defaultValues) {
        setIsUpdate(true);
        methods.setValue("ModelNumber", defaultValues.ModelNumber);
        methods.setValue("SerialNumber", defaultValues.SerialNumber);
      }
    }, [defaultValues, methods]);

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {data && (
            <FormDialog
              error={postScannerQuery.error}
              errorMessage={`Scanner could not be ${
                isUpdate ? "Updated" : "Created"
              }. Status Code: ${postScannerQuery.status}`}
              success={postScannerQuery.status === 200}
              successMessage={`Scanner has been ${
                isUpdate ? "updated" : "created"
              }.`}
              loading={postScannerQuery.loading}
              loadingMessage={`${isUpdate ? "Updating" : "Adding"} scanner...`}
              onClose={() => handleCloseFormDialog(postScannerQuery.error)}
              onReload={() => postScannerQuery.mutate(data)}
            />
          )}
          <Box>
            <Typography variant="h6" color="primary">
              Scanner Details
            </Typography>
          </Box>
          <Box borderTop={`2px solid ${theme.palette.primary.main}`}>
            <Controller
              name="ModelNumber"
              control={methods.control}
              defaultValue=""
              ref={methods.register}
              render={({ onBlur, onChange, value }) => (
                <ScannerModelSelect
                  formControlProps={{
                    fullWidth: true,
                    variant: "filled",
                    margin: "dense",
                    error: methods.errors?.ModelNumber && true,
                  }}
                  selectProps={{
                    onBlur: onBlur,
                    onChange: onChange,
                    value: value,
                    label: "Select Model",
                  }}
                  helperText={
                    methods.errors?.ModelNumber &&
                    methods.errors.ModelNumber.message
                  }
                />
              )}
            />
            <Controller
              as={TextField}
              name="SerialNumber"
              control={methods.control}
              variant="filled"
              label="Serial Number"
              fullWidth
              defaultValue=""
              margin="dense"
              error={methods.errors?.SerialNumber && true}
              helperText={
                methods.errors?.SerialNumber &&
                methods.errors.SerialNumber.message
              }
            />
          </Box>
          <FormActionsContainer
            boxSettings={{
              justifyContent: "left",
            }}
            fullWidthButtons={true}
            forwardButton={
              <PrimaryButton
                size="large"
                fullWidth
                type="submit"
                disabled={methods.formState.isSubmitting}
              >
                {defaultValues ? "Update" : "Submit"}
              </PrimaryButton>
            }
            backwardButton={
              <CancelButton
                size="large"
                fullWidth
                disabled={methods.formState.isSubmitting}
                onClick={
                  onCancel
                    ? () => {
                        onCancel();
                        methods.reset({
                          ModelNumber: "",
                          SerialNumber: "",
                        });
                      }
                    : () =>
                        methods.reset({
                          ModelNumber: "",
                          SerialNumber: "",
                        })
                }
              >
                Cancel
              </CancelButton>
            }
          />
        </form>
      </FormProvider>
    );
  },
);

export default ScannerForm;
