import { Box, CircularProgress, Collapse, Typography } from "@material-ui/core";
import React from "react";
import { BandedTableCellLessPadding, BandedTableRowAlt } from "../brand/Tables";

interface CheckDisplayProps {
  front?: {
    processing: boolean;
    processed: boolean;
    error?: string;
    path?: string | undefined;
    preview?: string | undefined;
  };
  back?: {
    processing: boolean;
    processed: boolean;
    error?: string;
    path?: string | undefined;
    preview?: string | undefined;
  };
  open: boolean;
}

const CheckImageContainer: React.FC<any> = (props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignContent="center"
    alignItems="center"
    width={1}
    minHeight={"125px"}
    border={1}
    bgcolor="white"
    borderColor="text.disabled"
    color="text.disabled"
  >
    {props.children}
  </Box>
);

const CheckImageProcessing = () => (
  <Box width={1} display="flex" justifyContent="center" alignItems="center">
    <CircularProgress color="inherit" size={24} />
    <Typography style={{ marginLeft: 16 }}>processing</Typography>
  </Box>
);

const CheckDisplay = ({ front, back, open }: CheckDisplayProps) => {
  return (
    <BandedTableRowAlt>
      <BandedTableCellLessPadding colSpan={8}>
        <Collapse in={open} timeout="auto">
          <Box minWidth={1} display="flex">
            <Box minWidth={1 / 2} pr={3}>
              <Typography variant="body2" gutterBottom>
                Front of Check
              </Typography>
              <CheckImageContainer>
                {front?.processing && <CheckImageProcessing />}
                {!front?.processing && front?.processed && (
                  <img src={front?.preview} alt="Front of Check" width="99%" />
                )}
              </CheckImageContainer>
            </Box>
            <Box minWidth={1 / 2} pr={3}>
              <Typography variant="body2" gutterBottom>
                Back of Check
              </Typography>
              <CheckImageContainer>
                {back?.processing && <CheckImageProcessing />}
                {!back?.processing && back?.processed && (
                  <img src={back?.preview} alt="Front of Check" width="99%" />
                )}
              </CheckImageContainer>
            </Box>
          </Box>
        </Collapse>
      </BandedTableCellLessPadding>
    </BandedTableRowAlt>
  );
};

export default CheckDisplay;
