import { Box, BoxProps } from "@material-ui/core";
import React, { FC } from "react";

interface FormActionsContainerProps {
  boxSettings?: BoxProps;
  forwardButton?: React.ReactNode;
  backwardButton?: React.ReactNode;
  fullWidthButtons?: boolean;
}

const FormActionsContainer: FC<FormActionsContainerProps> = ({
  boxSettings,
  forwardButton,
  backwardButton,
  fullWidthButtons=false,
  children,
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column-reverse", md: "row" }}
        justifyContent="space-between"
        margin="auto"
        mt={5}
        width={{ xs: (fullWidthButtons ? 1 : 3 / 5), md: 1 }}
        {...boxSettings}
      >
        {children ? (
          children
        ) : (
          <>
            {backwardButton && forwardButton && (
              <>
                <Box
                  mt={{ xs: 5, md: 0 }}
                  mr={3}
                  width={{ xs: 1, md: (fullWidthButtons ? 1 : 1 / 3) }}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                  display="flex"
                >
                  {backwardButton}
                </Box>
                <Box width={{ xs: 1, md: (fullWidthButtons ? 1 : 1 / 3) }}>{forwardButton}</Box>
              </>
            )}
            {backwardButton && !forwardButton && <>{backwardButton}</>}
            {forwardButton && !backwardButton && (
              <Box width={{ xs: 1, md: (fullWidthButtons ? 1 : 3 / 5) }}>{forwardButton}</Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default FormActionsContainer;
