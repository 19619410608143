import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContextType } from "react";
import { ClientContext } from "react-fetching-library";
import { uploadCheckAndConvertToJpeg } from "../../api/endpoints/scannerService";
import { ProcessingCheck } from "../../validations/entities";
import { RootState } from "../configureStore";
var XMLParser = require("react-xml-parser");

// interface
interface Checks {
  checks: {
    [key: string]: ProcessingCheck;
  };
}

// initial state
const initialState: Checks = {
  checks: {},
};

// ASYNC actions
export const handleUpload = createAsyncThunk(
  "checks/handleUpload",
  async (
    params: {
      clientContext: ContextType<typeof ClientContext>;
      userId: string;
      xmlString: string;
    },
    thunkApi,
  ) => {
    const checkId = thunkApi.requestId;
    try {
      // destructuring vars
      const { clientContext, userId, xmlString } = params;

      // initiate check
      thunkApi.dispatch(INITIATE_CHECK(checkId));
      thunkApi.dispatch(INITIATE_CHECK_FRONT(checkId));
      thunkApi.dispatch(INITIATE_CHECK_BACK(checkId));

      // get the micr, and images from check xml
      var xml = new XMLParser().parseFromString(xmlString);

      // micr line
      if (xml.getElementsByTagName("Micr").length > 0) {
        const micr: string = xml
          .getElementsByTagName("Micr")[0]
          .children.find((item: { name: string }) => item.name === "MicrLine")
          .value.replaceAll("T", "g")
          .replaceAll("O", "h")
          .replaceAll("A", "f")
          .replaceAll("D", "i");
        // convert the micr line values to bank known values
        // T -> g
        // O -> h
        // A -> f
        // D -> I
        thunkApi.dispatch(SET_CHECK_MICR({ id: checkId, micr: micr }));
      } else {
        thunkApi.rejectWithValue(checkId);
      }

      // front image
      if (xml.getElementsByTagName("ImageFront").length > 0) {
        // initiate the check
        const frontImage: string = xml
          .getElementsByTagName("ImageFront")[0]
          .children.find((item: { name: string }) => item.name === "Base64Data")
          .value;
        // upload the image and get the preview base64
        const [path, preview] = await uploadCheckAndConvertToJpeg(
          frontImage,
          userId,
          `${checkId}-front.tiff`,
          clientContext,
        );
        if (path && preview) {
          thunkApi.dispatch(
            SET_CHECK_FRONT_SUCCESS({
              id: checkId,
              path: path,
              preview: preview,
            }),
          );
        } else {
          thunkApi.dispatch(
            SET_CHECK_FRONT_FAILED({
              id: checkId,
              error: "Could not convert and upload image.",
            }),
          );
        }
      } else {
        thunkApi.dispatch(
          SET_CHECK_FRONT_FAILED({
            id: checkId,
            error: "Could not find image.",
          }),
        );
      }

      // back image
      if (xml.getElementsByTagName("ImageBack").length > 0) {
        // initiate check
        const backImage: string = xml
          .getElementsByTagName("ImageBack")[0]
          .children.find((item: { name: string }) => item.name === "Base64Data")
          .value;
        // upload the image and get the preview base64
        const [path, preview] = await uploadCheckAndConvertToJpeg(
          backImage,
          userId,
          `${checkId}-back.tiff`,
          clientContext,
        );
        if (path && preview) {
          thunkApi.dispatch(
            SET_CHECK_BACK_SUCCESS({
              id: checkId,
              path: path,
              preview: preview,
            }),
          );
        } else {
          thunkApi.dispatch(
            SET_CHECK_BACK_FAILED({
              id: checkId,
              error: "Could not convert and upload image.",
            }),
          );
        }
      } else {
        thunkApi.dispatch(
          SET_CHECK_BACK_FAILED({
            id: checkId,
            error: "Could not find image.",
          }),
        );
      }

      // return the checkId
      return checkId;
    } catch (err) {
      thunkApi.rejectWithValue("Could not process check.");
    }
  },
);

// slice
const checksSlice = createSlice({
  name: "checks",
  initialState,
  reducers: {
    CLEAR_CHECKS: (state) => {
      state.checks = {};
    },
    INITIATE_CHECK: (state, action: PayloadAction<string>) => {
      state.checks = {
        ...state.checks,
        [action.payload]: { processing: true, processed: false },
      };
    },
    DELETE_CHECK: (state, action: PayloadAction<string>) => {
      const res = state.checks;
      delete res[action.payload];
      state.checks = res;
    },
    SET_CHECK_MICR: (
      state,
      action: PayloadAction<{ id: string; micr: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          micr: action.payload.micr,
        },
      };
    },
    SET_CHECK_AMOUNT: (
      state,
      action: PayloadAction<{ id: string; amount: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          amount: action.payload.amount,
        },
      };
    },
    SET_CHECK_PAYOR: (
      state,
      action: PayloadAction<{ id: string; payor: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          payor: action.payload.payor,
        },
      };
    },
    SET_CHECK_NUMBER: (
      state,
      action: PayloadAction<{ id: string; number: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          number: action.payload.number,
        },
      };
    },
    SET_CHECK_ACCOUNT: (
      state,
      action: PayloadAction<{ id: string; account: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          accountNumber: action.payload.account,
        },
      };
    },
    INITIATE_CHECK_FRONT: (state, action: PayloadAction<string>) => {
      state.checks = {
        ...state.checks,
        [action.payload]: {
          ...state.checks[action.payload],
          front: { processing: true, processed: false },
        },
      };
    },
    SET_CHECK_FRONT_SUCCESS: (
      state,
      action: PayloadAction<{ id: string; path: string; preview: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          front: {
            processing: false,
            processed: true,
            path: action.payload.path,
            preview: action.payload.preview,
          },
        },
      };
    },
    SET_CHECK_FRONT_FAILED: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          front: {
            processing: false,
            processed: true,
            error: action.payload.error,
          },
        },
      };
    },
    INITIATE_CHECK_BACK: (state, action: PayloadAction<string>) => {
      state.checks = {
        ...state.checks,
        [action.payload]: {
          ...state.checks[action.payload],
          back: { processing: true, processed: false },
        },
      };
    },
    SET_CHECK_BACK_SUCCESS: (
      state,
      action: PayloadAction<{ id: string; path: string; preview: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          back: {
            processing: false,
            processed: true,
            path: action.payload.path,
            preview: action.payload.preview,
          },
        },
      };
    },
    SET_CHECK_BACK_FAILED: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      state.checks = {
        ...state.checks,
        [action.payload.id]: {
          ...state.checks[action.payload.id],
          back: {
            processing: false,
            processed: true,
            error: action.payload.error,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleUpload.fulfilled, (state, action) => {
      const checkId = action.payload ? action.payload : action.meta.requestId;
      state.checks = {
        ...state.checks,
        [checkId]: {
          ...state.checks[checkId],
          processed: true,
          processing: false,
        },
      };
    });
    builder.addCase(handleUpload.rejected, (state, action) => {
      const micrError = action.payload ? true : false;
      state.checks = {
        ...state.checks,
        [action.meta.requestId]: {
          ...state.checks[action.meta.requestId],
          processed: true,
          processing: false,
          error: micrError
            ? "Micr line could not be read"
            : "Could not get check details",
        },
      };
    });
  },
});

// Export selectors
export const selectChecks = (state: RootState) => state.checks.checks;

// Export actions
export const {
  CLEAR_CHECKS,
  INITIATE_CHECK,
  SET_CHECK_MICR,
  SET_CHECK_ACCOUNT,
  SET_CHECK_AMOUNT,
  SET_CHECK_NUMBER,
  SET_CHECK_PAYOR,
  DELETE_CHECK,
  INITIATE_CHECK_FRONT,
  SET_CHECK_FRONT_SUCCESS,
  SET_CHECK_FRONT_FAILED,
  INITIATE_CHECK_BACK,
  SET_CHECK_BACK_SUCCESS,
  SET_CHECK_BACK_FAILED,
} = checksSlice.actions;

// Export reducer
export default checksSlice.reducer;
