import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  SelectProps,
} from "@material-ui/core";
import { ArrowDropDownCircle } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { theme } from "../../constants/theme";
import { selectCustomerAccounts } from "../../redux/slices/configSlice";
import { TableSelect } from "../brand/Inputs";

const iconComponent = (props: { className: string }) => {
  return (
    <ArrowDropDownCircle
      className={props.className}
      fontSize="inherit"
      style={{ marginTop: theme.spacing(2) / 2 }}
    />
  );
};

const AccountSelect = (props: {
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  helperText?: string;
}) => {
  const accounts = useSelector(selectCustomerAccounts);
  return (
    <FormControl {...props.formControlProps} size="small">
      <TableSelect
        {...props.selectProps}
        variant="outlined"
        IconComponent={iconComponent}
      >
        {accounts?.map((account, index) => (
          <MenuItem
            key={`account-${account.accountNum}-${index}`}
            value={account.accountNum}
          >
            {`${account.accountNum} - ${account.accountNickname}`}
          </MenuItem>
        ))}
      </TableSelect>
      {props.helperText && (
        <FormHelperText
          margin="dense"
          style={{ fontSize: theme.typography.pxToRem(10) }}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default AccountSelect;
