import { Action } from "react-fetching-library";
const queryString = require("query-string");

/*  Main Processing Functions */
var defaultScannerEndpoint = "https://usb.rdmscanners.net/SCI/7.0/sci.esp?";
var defaultHeaders = {
  "Content-Type": "application/x-www-form-urlencoded",
};

/** Defines the type of scanner to claim.
 * @typedef "AnyScanner" - AnyScanner (Claim any connected RDM scanner);
 * @typedef "RdmTp2UsbScanner" - RdmTp2UsbScanner (These scanners belong to the EC6000 and EC7000 scanner families.);
 * @typedef "RdmSimUsbScanner" - RdmSimUsbScanner (These scanners belong to the EC7500 and EC9000 scanner families.);
 * @typedef "RdmNetworkScanner" - RdmNetworkScanner (These scanners belong to the EC9600 scanner family.);
 */
export type ScannerType =
  | "AnyScanner"
  | "RdmTp2UsbScanner"
  | "RdmSimUsbScanner"
  | "RdmNetworkScanner";

/**
 * The ClaimScanner method is used to establish exclusive communication with a connected scanner via Remote Server.
 * @param {string} UserId - unique string provided by the calling application.
 * @param {ScannerType} ScannerType - an optional parameter. type of scanner to claim.
 */
export const claimScanner = ({
  UserId,
  Function = "ClaimScanner",
  ScannerType,
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "ClaimScanner";
  ScannerType?: ScannerType;
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(
    queryString.stringify({ UserId, Function, ScannerType }),
  ),
  headers: defaultHeaders,
  config: {
    skipAuth: true,
  },
});

/**
 * The type of operation to start. See below.
 * @typedef "ScanSingleItem" - Scan a single item.One ResponseData shall be added to the response queue.
 * @typedef "ScanDecisionItem" - Scan a single item allowing for overriding directives.
  One ResponseData shall be added to the response queue.
  Note: CompleteOperation must be used to complete the scan.
 * @typedef "ScanBatchItems" - Scan several items consecutively.
  One ResponseData for each item shall be added to the response queue.
 * @typedef "ScanIdCard" - Scan an ID card.
 * @typedef "SwipeMsrCard" - Scan an ID card.
 * @typedef "Print" - Sends base64 data to the printer.
 * @typedef "RunCleanCycle" - Optionally executes a clean cycle and updates clean cycle information.
 */
export type StartType =
  | "ScanSingleItem"
  | "ScanDecisionItem"
  | "ScanBatchItems"
  | "ScanIdCard"
  | "SwipeMsrCard"
  | "Print"
  | "RunCleanCycle";

/**
 * The StartOperation method is used to asynchronously start an operation of type StartType
 * @param {string} UserId - same string used during ClaimScanner.
 * @param {StartType} StartType -the type of operation to start.
 */
export const startOperation = ({
  UserId,
  Function = "StartOperation",
  StartType,
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "StartOperation";
  StartType: StartType;
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(
    queryString.stringify({ Function, StartType, UserId }),
  ),
  headers: defaultHeaders,
  config: {
    skipAuth: true,
  },
});

/**
 * The GetResponseXml method is used to return ResponseData from the Remote Server queue.
 * @param {string} UserId - same string used during ClaimScanner.
 * @param {boolean} Wait - an optional parameter. wait (for a maximum 15 seconds) for ResponseData to arrive.
 */
export const getResponseXml = ({
  UserId,
  Function = "GetResponseXml",
  Wait,
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "GetResponseXml";
  Wait?: boolean;
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(
    queryString.stringify({ UserId, Function, Wait }),
  ),
  headers: defaultHeaders,
  config: {
    skipAuth: true,
  },
});

/**
 * @typedef "DecisionAccept" - Used after a StartOperation with a StartType of ScanDecisionItem to accept the current item.
Response data generated by the Complete Operation will have a [Accepted] notification.
 * @typedef "DecisionReject" - Used after a StartOperation with a StartType of ScanDecisionItem to reject the current item.
Response data generated by the Complete Operation will have a [Rejected] notification.
 * @typedef "OperationStop" - Used after a StartOperation to stop the operation and return the scanner to the Idle state.
Response data generated by the Start Operation will have a [Stopped] notification.
 * @typedef "OperationAbort" - Used anytime to abort the current operation and return the scanner to the Idle state.
All response data is removed from the internal response queue.
 * @typedef "ErrorAck" - Used to acknowledge an error state and if possible return the scanner to the Idle state.
Response data will have a [ErrorAck] notification.
 * @typedef "PrintWait" - Used after a StartOperation with a StartType of Print to determine if the Print has completed.
Response data will have a [PrintComplete] notification
 */
export type CompleteType =
  | "DecisionAccept"
  | "DecisionReject"
  | "OperationStop"
  | "OperationAbort"
  | "ErrorAck"
  | "PrintWait";

/** The CompleteOperation method is used to complete an operation started by StartOperation.
 * @param {string} UserId - same string used during ClaimScanner.
 * @param {CompleteType} Function - contains the type of completion. @see CompleteType
 */
export const completeOperation = ({
  UserId,
  Function = "CompleteOperation",
  CompleteType,
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "CompleteOperation";
  CompleteType: CompleteType;
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(
    queryString.stringify({ UserId, Function, CompleteType }),
  ),
  headers: defaultHeaders,
});

/** Release the scanner.
 * @param {string} UserId - same string used during ClaimScanner.
 */
export const releaseScanner = ({
  UserId,
  Function = "ReleaseScanner",
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "ReleaseScanner";
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(queryString.stringify({ UserId, Function })),
  headers: defaultHeaders,
  config: {
    skipAuth: true,
  },
});

/* Support Functions */

/**
 * SetBaselineOperationXml establishes a set of start operation settings for use as a baseline.
 * @param {string} UserId - same string used during ClaimScanner.
 * @param {string} Function - will be used to establish a baseline by interrogating the connected scanner and providing as much functionality as is supported.
 * @see getOperationXml to determine what functionality is active.
 */
export const setBaselineOperationXml = ({
  UserId,
  Function = "SetBaselineOperationXml",
  BaselineOperationXml,
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "SetBaselineOperationXml";
  BaselineOperationXml: string;
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(
    queryString.stringify({ UserId, Function, BaselineOperationXml }),
  ),
  headers: defaultHeaders,
});

/**
 * GetOperationXml method is used to determine the current base operation settings.
 * @param {string} UserId - same string used during ClaimScanner.
 */
export const getOperationXml = ({
  UserId,
  Function = "GetOperationXml",
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "GetOperationXml";
  scannerEndpoint: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(queryString.stringify({ UserId, Function })),
  headers: defaultHeaders,
});

/**
 * Returns details of the scanner that was found during ClaimScanner
 * @param {string} UserId - same string used during ClaimScanner.
 */
export const getScannerXml = ({
  UserId,
  Function = "GetScannerXml",
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "GetScannerXml";
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(queryString.stringify({ UserId, Function })),
  headers: defaultHeaders,
});

/**
 * GetSciVersion returns the version of the RdmSci API
 */
export const getSciVersion = ({
  scannerEndpoint = defaultScannerEndpoint,
}: {
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat("GetSciVersion"),
  headers: defaultHeaders,
});

/**
 * GetState returns the current State.
 * @param {string} UserId - same string used during ClaimScanner.
 */
export const getState = ({
  UserId,
  Function = "GetState",
  scannerEndpoint = defaultScannerEndpoint,
}: {
  UserId: string;
  Function?: "GetState";
  scannerEndpoint?: string;
}): Action<string> => ({
  method: "POST",
  endpoint: scannerEndpoint.concat(queryString.stringify({ UserId, Function })),
  headers: defaultHeaders,
});
