/* eslint-disable no-template-curly-in-string */

const maxLengthMessage = "Must be less than ${max} characters";
const minLengthMessage = "Must be at least ${min} characters";
const requiredMessage = "Required";
const invalidMessage = "Must be valid";
// Email Address
const invalidEmailMessage = "Must be a valid email address";
// Phone Number
// (Database only accepts 10 varchar so stripping values or masking may be required here?)
const isPhoneNumber = /\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/;
const invalidPhoneNumberMessage = "Must be a valid phone number";
const isISOCode = /(\+?( |-|\.)?\d{1,2})/;
const invalidISOCodeMessage = "Must be a valid ISO code";
// Alphanumeric only
const isAlphanumeric = /^[a-zA-Z0-9 ]*$/;
const invalidAlphanumeric = "Must only contain letters, numbers and spaces.";
// Whole Number
const isNumber = /^[0-9]*$/;
const invalidNumberMessage = "Must contain only numbers";
// Currency
const isCurrency = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
const invalidCurrencyMessage = "Currency Format is invalid";
// Decimal Number
const isDecimal = ({
  precision = 18,
  scale = 2,
}: {
  precision?: number;
  scale?: number;
}) => {
  const pointsBeforeDecimal = precision - scale;
  const dynamicRegex = `/^[0-9]{1,${pointsBeforeDecimal}}[.][0-9]{1,${scale}}$/`;
  return new RegExp(dynamicRegex);
};
const invalidDecimalMessage = ({
  precision = 18,
  scale = 2,
}: {
  precision?: number;
  scale?: number;
}) => {
  const pointsBeforeDecimal = precision - scale;
  return `Must be a valid decimal number with ${pointsBeforeDecimal} numbers before the decimal and up to ${scale} numbers after.`;
};

export {
  maxLengthMessage,
  minLengthMessage,
  requiredMessage,
  invalidMessage,
  invalidEmailMessage,
  isPhoneNumber,
  isCurrency,
  invalidCurrencyMessage,
  invalidPhoneNumberMessage,
  isISOCode,
  invalidISOCodeMessage,
  isNumber,
  invalidNumberMessage,
  isDecimal,
  invalidDecimalMessage,
  isAlphanumeric,
  invalidAlphanumeric,
};

// Handle MICR Line validation
export const validMicrLine = (micrLine: string) => {
  //routing number - 9 digits
  const routingNumber = new RegExp(/g[0-9]{9}g/);

  //account number - between 3 and 15 digits
  const accountNumber = new RegExp(/h[0-9]{3,15}h/);

  //amount - between 1 and 25 digits
  const amount = new RegExp(/f[0-9]{1,25}f/);
  const amountExists = new RegExp(/f/);

  if (routingNumber.test(micrLine) && accountNumber.test(micrLine)) {
    if (amountExists.test(micrLine)) {
      return amount.test(micrLine);
    } else {
      return true;
    }
  } else {
    return false;
  }
};
