import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React from "react";

interface AppBackdropProps {
  open: boolean;
  message: string;
}

const AppBackdrop = ({ open, message }: AppBackdropProps) => {
  return (
    <Backdrop open={open}>
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Box p={3}>
              <CircularProgress color="secondary" />
            </Box>
            <Typography>{message}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Backdrop>
  );
};

export default AppBackdrop;
