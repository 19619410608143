import {
  createClient,
  Client,
  Action,
  createCache,
  QueryResponse,
} from "react-fetching-library";
import { rootStore } from "../redux/configureStore";

const requestHostInterceptor = (host: string) => (_: Client) => async (
  action: any,
) => {
  if (action?.config?.skipAuth) {
    return { ...action, endpoint: `${host}${action.endpoint}` };
  }

  const authenticationState: any = rootStore.getState().authentication.idToken;

  return {
    ...action,
    headers: {
      ...action.headers,
      "Device-Platform": "web",
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
      "Authorization": `Bearer ${authenticationState.idToken.rawIdToken}`
    },
    endpoint: `${host}${action.endpoint}`,
  };
};

const cache = createCache<QueryResponse<any>>(
  (action: Action) => {
    return action.method === "GET";
  },
  (response: QueryResponse<any> & { timestamp: number }) => {
    return new Date().getTime() - response.timestamp < 10000;
  },
);

export function getApiClient() {
  return createClient({
    requestInterceptors: [requestHostInterceptor("")],
    responseInterceptors: [],
    cacheProvider: cache,
  });
}
