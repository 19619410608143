import storageSession from "redux-persist/lib/storage/session";
import { rootReducer } from "./slices";
import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { AuthenticationActions } from "react-aad-msal";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["authentication"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const rootStore = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        AuthenticationActions.Initializing,
        AuthenticationActions.Initialized,
        AuthenticationActions.AcquiredIdTokenSuccess,
        AuthenticationActions.AcquiredAccessTokenSuccess,
        AuthenticationActions.AcquiredAccessTokenError,
        AuthenticationActions.LoginSuccess,
        AuthenticationActions.LoginError,
        AuthenticationActions.AcquiredIdTokenError,
        AuthenticationActions.LogoutSuccess,
        AuthenticationActions.AuthenticatedStateChanged,
      ],
    },
  }),
});

const persister = persistStore(rootStore);

export { rootStore, persister };

export type RootState = ReturnType<typeof persistedReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
