import React from "react";
import {
  Box,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { theme } from "../../constants/theme";
import { CancelButton, OutlinedButton, PrimaryButton } from "../brand/Buttons";
import FormActionsContainer from "../../containers/FormActionsContainer";
import { BandedTableCell, BandedTableRow } from "../brand/Tables";
import DeleteIcon from "@material-ui/icons/Delete";
import { CustomerPost, CustomerPostSuccess } from "../../validations/entities";
import {
  ADD_CUSTOMER_SCHEMA,
  UPDATE_CUSTOMER_SCHEMA,
} from "../../validations/schemas";
import ScannerSelect from "./ScannerSelect";
import { QueryResponse, useQuery } from "react-fetching-library";
import {
  getAccountsAction,
  getScannersAction,
} from "../../api/endpoints/cpaService";
import FormDialog from "../common/FormDialog";
import AccountSelect from "./AccountSelect";

interface Props {
  postCustomerQuery: {
    loading: boolean;
    error: boolean;
    status?: number;
    mutate: (
      action: CustomerPost,
    ) => Promise<QueryResponse<CustomerPostSuccess>>;
  };
  onSuccess: () => void;
  updateCustomerQuery: {
    loading: boolean;
    error: boolean;
    status?: number;
    mutate: (
      action: Omit<CustomerPost, "ObjectId">,
    ) => Promise<QueryResponse<CustomerPostSuccess>>;
  };
  defaultValues?: Omit<Partial<CustomerPost>, "ObjectId">;
  onCancel?: () => void;
}

const CustomerForm = React.memo(
  ({
    onSuccess,
    postCustomerQuery,
    updateCustomerQuery,
    defaultValues,
    onCancel,
  }: Props) => {
    const [accountId, setAccountId] = React.useState<string>("");
    const [data, setData] = React.useState<any>();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const methods = useForm({
      resolver: yupResolver(
        defaultValues ? UPDATE_CUSTOMER_SCHEMA : ADD_CUSTOMER_SCHEMA,
      ),
      defaultValues: {
        ObjectId: "",
        LegacyCustomerId: "",
        FirstName: "",
        LastName: "",
        ScannerId: "",
        Accounts: [] as string[],
      },
    });
    const { fields, append, remove } = useFieldArray<{
      id: string;
      value: string;
    }>({
      control: methods.control,
      name: "Accounts",
    });
    const accountsQuery = useQuery(getAccountsAction());
    const scannerQuery = useQuery(getScannersAction());

    const handleAddAccount = () => {
      if (accountId && !isDuplicateAccountId(accountId)) {
        const splitValues = accountId.split(";");
        setAccountId("");
        append({ id: splitValues[0], value: splitValues[1] });
      } else {
        setAccountId("");
      }
    };

    const isDuplicateAccountId = (accountId: string) => {
      if (fields.find((x) => x.id === accountId.split(";")[0]) !== undefined) {
        alert("That Account Id is already added.");
        return true;
      } else {
        return false;
      }
    };

    const handleAccountIdFieldChange = (event: any) => {
      setAccountId(event.target.value);
    };

    const handleCloseFormDialog = (error: boolean) => {
      setData(undefined);
      if (!error) {
        handleReset();
      }
    };

    const onSubmit = (data: any) => {
      const newCustomer: CustomerPost | Omit<CustomerPost, "ObjectId"> = {
        ObjectId: defaultValues ? undefined : data.ObjectId,
        CustomerId: defaultValues?.CustomerId,
        LegacyCustomerId: data.LegacyCustomerId,
        FirstName: data.FirstName,
        LastName: data.LastName,
        ScannerId: data.ScannerId,
        Accounts: data.Accounts.map(
          (account: { value: string }) => account.value,
        ),
      };
      setData(newCustomer);
      if (isUpdate) {
        updateCustomerQuery.mutate(newCustomer).then((res) => {
          if (res.status === 200) {
            onSuccess();
          }
        });
      } else {
        postCustomerQuery.mutate(newCustomer).then((res) => {
          if (res.status === 200) {
            onSuccess();
          }
        });
      }
    };

    const handleReset = () => {
      methods.clearErrors([
        "ObjectId",
        "LegacyCustomerId",
        "FirstName",
        "LastName",
        "ScannerId",
        "Accounts",
      ]);
      methods.reset({
        ObjectId: "",
        LegacyCustomerId: "",
        FirstName: "",
        LastName: "",
        ScannerId: "",
      });
    };

    React.useEffect(() => {
      if (defaultValues) {
        setIsUpdate(true);
        methods.setValue("FirstName", defaultValues.FirstName);
        methods.setValue("LastName", defaultValues.LastName);
        methods.setValue("LegacyCustomerId", defaultValues.LegacyCustomerId);
        methods.setValue("ScannerId", defaultValues.ScannerId);
        let defAccts =
          defaultValues?.Accounts &&
          defaultValues.Accounts.map((acc: string, index: number) => {
            var split = acc.split(";");
            return { id: split[0], value: split[1] };
          });
        if (defAccts && defAccts !== null) {
          append(defAccts);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    return (
      <>
        {data &&
          (isUpdate ? (
            <FormDialog
              error={updateCustomerQuery.error}
              errorMessage={`Customer could not be created. Status Code: ${updateCustomerQuery.status}`}
              success={updateCustomerQuery.status === 200}
              successMessage={"Customer has been updated."}
              loading={updateCustomerQuery.loading}
              loadingMessage={"Updating Customer..."}
              onClose={() => handleCloseFormDialog(updateCustomerQuery.error)}
              onReload={() => updateCustomerQuery.mutate(data)}
            />
          ) : (
            <FormDialog
              error={postCustomerQuery.error}
              errorMessage={`Customer could not be updated. Status Code: ${postCustomerQuery.status}`}
              success={postCustomerQuery.status === 200}
              successMessage={`Customer has been created.`}
              loading={postCustomerQuery.loading}
              loadingMessage={"Creating Customer..."}
              onClose={() => handleCloseFormDialog(postCustomerQuery.error)}
              onReload={() => postCustomerQuery.mutate(data)}
            />
          ))}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <Typography variant="h6" color="primary">
                Customer Details
              </Typography>
            </Box>
            <Box borderTop={`2px solid ${theme.palette.primary.main}`}>
              <Controller
                variant="filled"
                as={TextField}
                control={methods.control}
                label="First Name"
                name="FirstName"
                fullWidth
                margin="dense"
                defaultValue=""
                error={methods.errors?.FirstName && true}
                helperText={
                  methods.errors?.FirstName && methods.errors.FirstName.message
                }
              />
              <Controller
                variant="filled"
                as={TextField}
                control={methods.control}
                label="Last Name"
                name="LastName"
                fullWidth
                margin="dense"
                defaultValue=""
                error={methods.errors?.LastName && true}
                helperText={
                  methods.errors?.LastName && methods.errors.LastName.message
                }
              />
              {!defaultValues && (
                <Controller
                  variant="filled"
                  as={TextField}
                  control={methods.control}
                  label="Object Id"
                  name="ObjectId"
                  fullWidth
                  margin="dense"
                  defaultValue=""
                  error={methods.errors?.ObjectId && true}
                  helperText={
                    methods.errors?.ObjectId && methods.errors.ObjectId.message
                  }
                />
              )}
              <Controller
                variant="filled"
                as={TextField}
                control={methods.control}
                label="Legacy Customer Id"
                name="LegacyCustomerId"
                fullWidth
                margin="dense"
                defaultValue=""
                error={methods.errors?.LegacyCustomerId && true}
                helperText={
                  methods.errors?.LegacyCustomerId &&
                  methods.errors.LegacyCustomerId.message
                }
              />
              <Controller
                name="ScannerId"
                control={methods.control}
                defaultValue=""
                ref={methods.register}
                render={({ onBlur, onChange, value }) => (
                  <ScannerSelect
                    loading={scannerQuery.loading}
                    error={scannerQuery.error}
                    onReload={scannerQuery.query}
                    scanners={scannerQuery.payload}
                    formControlProps={{
                      fullWidth: true,
                      variant: "filled",
                      margin: "dense",
                      error: methods.errors?.ScannerId && true,
                    }}
                    selectProps={{
                      onBlur: onBlur,
                      onChange: onChange,
                      value: value,
                      label: "Select Scanner",
                    }}
                    helperText={
                      methods.errors?.ScannerId &&
                      methods.errors.ScannerId.message
                    }
                  />
                )}
              />
            </Box>
            <Box mt={4}>
              <Typography variant="h6" color="primary">
                Assign Accounts
              </Typography>
            </Box>
            <Box borderTop={`2px solid ${theme.palette.primary.main}`}>
              <Box width={1} display="flex" alignContent="center">
                <Box width={3 / 4} mr={2}>
                  <AccountSelect
                    loading={accountsQuery.loading}
                    error={accountsQuery.error}
                    onReload={accountsQuery.query}
                    accounts={accountsQuery.payload}
                    formControlProps={{
                      fullWidth: true,
                      variant: "filled",
                      margin: "dense",
                      error: methods.errors?.Accounts && true,
                    }}
                    helperText={
                      methods.errors?.Accounts &&
                      methods.errors.Accounts[0]?.message
                    }
                    selectProps={{
                      defaultValue: "",
                      onChange: handleAccountIdFieldChange,
                      value: accountId,
                    }}
                  />
                </Box>
                <Box width={1 / 4} mt={2}>
                  <OutlinedButton
                    size="large"
                    color="secondary"
                    fullWidth
                    style={{ height: "50px" }}
                    onClick={() => handleAddAccount()}
                  >
                    Add
                  </OutlinedButton>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: "8px",
                    borderCollapse: "inherit",
                  }}
                >
                  <TableBody>
                    {fields &&
                      fields.map((account, index) => (
                        <BandedTableRow key={`Accounts[${index}]`}>
                          <BandedTableCell align="left">
                            <Typography>{account.value}</Typography>
                            <InputBase
                              defaultValue={account.id}
                              inputRef={methods.register}
                              name={`Accounts[${index}].value`}
                              style={{ backgroundColor: "transparent" }}
                              readOnly
                            />
                          </BandedTableCell>
                          <BandedTableCell align="right">
                            <Box mr={1}>
                              <IconButton
                                size="small"
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Box>
                          </BandedTableCell>
                        </BandedTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <FormActionsContainer
              boxSettings={{
                justifyContent: "left",
              }}
              fullWidthButtons={true}
              forwardButton={
                <PrimaryButton size="large" fullWidth type="submit">
                  {defaultValues ? "Update" : "Submit"}
                </PrimaryButton>
              }
              backwardButton={
                <CancelButton
                  size="large"
                  fullWidth
                  onClick={
                    onCancel
                      ? () => {
                          onCancel();
                          handleReset();
                        }
                      : () => handleReset()
                  }
                >
                  Cancel
                </CancelButton>
              }
            />
          </form>
        </FormProvider>
      </>
    );
  },
);

export default CustomerForm;
