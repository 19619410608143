import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import {
  BorderSettings,
  BrandOverrides,
  breakpoints,
  FontSettings,
  LightPalette,
  space,
} from "./brand";

export const theme = responsiveFontSizes(
  createMuiTheme({
    breakpoints: {
      values: {
        ...breakpoints,
      },
    },
    palette: LightPalette,
    typography: {
      ...FontSettings.fontVariants,
    },
    spacing: space,
    shape: {
      borderRadius: BorderSettings.borderRadius,
    },
    overrides: BrandOverrides,
  }),
);
