import { IconButton, Tooltip } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import { theme } from "../../constants/theme";
import { Account, AccountPost } from "../../validations/entities";
import ConfirmDialog from "../common/ConfirmDialog";
import QueryDataGrid from "../common/QueryDataGrid";

interface AccountListProps {
  loading: boolean;
  error: boolean;
  onReload: () => Promise<QueryResponse<Account[]>>;
  accounts?: Account[];
  deleteAccount: (accountId: string) => void;
  updateAccount: React.Dispatch<React.SetStateAction<AccountPost | undefined>>;
}

const AccountList = ({
  loading,
  error,
  onReload,
  accounts,
  deleteAccount,
  updateAccount,
}: AccountListProps) => {
  const [accountId, setAccountId] = React.useState<string>();
  const initiateDelete = (accountId: string) => {
    setAccountId(accountId);
  };

  const cancelDelete = () => {
    setAccountId(undefined);
  };

  const handleDelete = (accountId: string) => {
    deleteAccount(accountId);
    setAccountId(undefined);
  };
  const columns: GridColDef[] = [
    { field: "accountNum", headerName: "Number", flex: 0.5 },
    { field: "accountNickname", headerName: "Nickname", flex: 0.5 },
    {
      field: "isLegacy",
      headerName: "Legacy",
      flex: 0.25,
    },
    {
      field: "accountId",
      renderHeader: () => {
        return <></>;
      },
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const value = params.value;
        const accountNum = params.getValue("accountNum");
        const accountNickname = params.getValue("accountNickname");
        const isLegacy = params.getValue("isLegacy");
        const updateObject: AccountPost = {
          AccountId: value ? value.toString() : "",
          AccountNum: accountNum ? accountNum.toString() : "",
          AccountNickname: accountNickname ? accountNickname.toString() : "",
          IsLegacy: isLegacy
            ? isLegacy.toString() === "true"
              ? true
              : false
            : false,
        };
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => updateAccount(updateObject)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                style={{ color: theme.palette.error.main }}
                onClick={() => initiateDelete(value ? value.toString() : "")}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      flex: 0.15,
    },
  ];
  return (
    <>
      {accountId && (
        <ConfirmDialog
          open={true}
          message={`Are you sure you want to delete this account? `}
          onConfirm={() => handleDelete(accountId)}
          onDismiss={() => cancelDelete()}
        />
      )}
      <QueryDataGrid
        loading={loading}
        error={error}
        onReload={onReload}
        payload={accounts}
        columns={columns}
      />
    </>
  );
};

export default AccountList;
