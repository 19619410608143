import * as Yup from "yup"; // for everything
import {
  invalidAlphanumeric,
  invalidNumberMessage,
  isAlphanumeric,
  isCurrency,
  isNumber,
  maxLengthMessage,
  requiredMessage,
} from "./helpers";

export const ADD_CUSTOMER_SCHEMA = Yup.object().shape({
  ObjectId: Yup.string()
    .trim()
    .max(50, maxLengthMessage)
    .required(requiredMessage),
  LegacyCustomerId: Yup.string()
    .trim()
    .max(18, maxLengthMessage)
    .matches(isNumber, invalidNumberMessage)
    .required(requiredMessage),
  FirstName: Yup.string()
    .trim()
    .max(50, maxLengthMessage)
    .matches(isAlphanumeric, invalidAlphanumeric)
    .required(requiredMessage),
  LastName: Yup.string()
    .trim()
    .max(50, maxLengthMessage)
    .matches(isAlphanumeric, invalidAlphanumeric)
    .required(requiredMessage),
  ScannerId: Yup.string().max(50, maxLengthMessage).required(requiredMessage),
  Accounts: Yup.array()
    .of(
      Yup.object().shape({
        Account: Yup.string()
          .max(18, maxLengthMessage)
          .matches(isNumber, invalidNumberMessage),
      }),
    )
    .required("At least one Account Id is required"),
});

export const UPDATE_CUSTOMER_SCHEMA = Yup.object().shape({
  FirstName: ADD_CUSTOMER_SCHEMA.fields.FirstName,
  LastName: ADD_CUSTOMER_SCHEMA.fields.LastName,
  LegacyCustomerId: ADD_CUSTOMER_SCHEMA.fields.LegacyCustomerId,
  ScannerId: ADD_CUSTOMER_SCHEMA.fields.ScannerId,
  Accounts: ADD_CUSTOMER_SCHEMA.fields.Accounts,
});

export const CHECK_SCHEMA = Yup.object().shape({
  amount: Yup.string()
    .matches(isCurrency, "Invalid Amount")
    .required(requiredMessage),
  payor: Yup.string()
    .max(50, maxLengthMessage)
    .matches(isAlphanumeric, invalidAlphanumeric)
    .required(requiredMessage),
  micr: Yup.string()
    .max(50, maxLengthMessage)
    .matches(isAlphanumeric, "Invalid Micr")
    .required(requiredMessage),
  number: Yup.string()
    .max(50, maxLengthMessage)
    .matches(isNumber, invalidNumberMessage)
    .required(requiredMessage),
  accountNumber: Yup.string()
    .max(18, maxLengthMessage)
    .matches(isNumber, invalidNumberMessage)
    .required(requiredMessage),
});

export const BATCH_SCHEMA = Yup.object().shape({
  batchName: Yup.string()
    .max(50, maxLengthMessage)
    .matches(isAlphanumeric, invalidAlphanumeric)
    .required(requiredMessage),
  batchDescription: Yup.string()
    .matches(isAlphanumeric, invalidAlphanumeric)
    .max(50, maxLengthMessage)
    .required(requiredMessage),
});

export const SCANNER_SCHEMA = Yup.object().shape({
  ModelNumber: Yup.string()
    .max(6, maxLengthMessage)
    .matches(isAlphanumeric, invalidAlphanumeric)
    .required(requiredMessage),
  SerialNumber: Yup.string()
    .max(50, maxLengthMessage)
    .matches(isNumber, invalidNumberMessage)
    .required(requiredMessage),
});

export const ACCOUNT_SCHEMA = Yup.object().shape({
  AccountNum: Yup.string()
    .max(18, maxLengthMessage)
    .matches(isNumber, invalidNumberMessage)
    .required(requiredMessage),
  AccountNickname: Yup.string()
    .max(50, maxLengthMessage)
    .matches(isAlphanumeric, invalidAlphanumeric)
    .required(requiredMessage),
  IsLegacy: Yup.boolean().required(requiredMessage),
});
