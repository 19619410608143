import { ContextType } from "react";
import { ClientContext } from "react-fetching-library";
import {
  claimScanner,
  getResponseXml,
  releaseScanner,
  startOperation,
} from "../../api/endpoints/rdmService";
import { RootState } from "../configureStore";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleUpload } from "./checksSlice";
// uncomment for testing
// import { testCheckXml } from "../../constants/testCheckTwo";

// interface
interface Scanner {
  serialNumber: string;
  scanning: boolean;
  error?: string;
  response?: string;
}

// initial state
const initialState: Scanner = {
  serialNumber: "",
  scanning: false,
};

// ASYNC Actions
export const handleScan = createAsyncThunk(
  "scanner/handleScan",
  async (
    params: {
      clientContext: ContextType<typeof ClientContext>;
      userId: string;
    },
    thunkApi,
  ) => {
    try {
      // destructuring the parameters
      const { clientContext, userId } = params;

      /*       
      // uncomment for testing
      thunkApi.dispatch(
        handleUpload({ clientContext, userId, xmlString: testCheckXml }),
      );
      return testCheckXml; 
      */

      // release the scanner
      const release = await clientContext.query(
        releaseScanner({ UserId: userId }),
      );
      if (release.error || release.status !== 200 || !release.payload) {
        return thunkApi.rejectWithValue(
          "Could not communicate with Scanner hardware.",
        );
      } else {
        // Make sure the scanner is not being used by another user
        if (
          release.payload !== "Success" &&
          release.payload !== "Not Claimed"
        ) {
          return thunkApi.rejectWithValue(
            "Scanner could not be released. Make sure it is not being used by another process.",
          );
        }
      }

      // claim the scanner
      const claim = await clientContext.query(claimScanner({ UserId: userId }));
      if (claim.error || claim.status !== 200 || !claim.payload) {
        return thunkApi.rejectWithValue("Could not communicate with scanner.");
      } else {
        // Success means an established connection, other responses mean it is already claimed
        if (claim.payload !== "Success") {
          return thunkApi.rejectWithValue(
            "Scanner is already claimed. Make sure it is not being used by another process.",
          );
        }
      }

      // send the scanSingleItem startOperation request
      const scanSingleItem = await clientContext.query(
        startOperation({
          UserId: userId,
          StartType: "ScanSingleItem",
        }),
      );
      if (
        scanSingleItem.error ||
        scanSingleItem.status !== 200 ||
        !scanSingleItem.payload
      ) {
        return thunkApi.rejectWithValue(
          "Could not complete scan, check the alignment of the check in the hopper.",
        );
      } else {
        if (scanSingleItem.payload !== "Success") {
          // TODO - decode error codes scanner can return
          return thunkApi.rejectWithValue(scanSingleItem.payload);
        }
      }

      // wait for a maximum of 20 seconds for results
      const delay = () => new Promise((resolve) => setTimeout(resolve, 2000));

      //  setup the retries
      let result = undefined;

      for (let retry = 1; retry <= 10; retry++) {
        if (!result) {
          // wait for 5 seconds
          await delay();
          const response = await clientContext.query(
            getResponseXml({
              UserId: userId,
            }),
          );
          if (!response.error && response.status === 200 && response.payload) {
            result = response.payload;
          }
        }
      }

      // Release the scanner with the current userId
      await clientContext.query(releaseScanner({ UserId: userId }));

      if (result) {
        thunkApi.dispatch(
          handleUpload({ clientContext, userId, xmlString: result }),
        );
        return result;
      } else {
        return thunkApi.rejectWithValue("Could not get scanner results.");
      }

      // get the values from the scanner queue
    } catch (err) {
      if (typeof err === "string") {
        thunkApi.rejectWithValue(new Error(err));
      }
    }
  },
);

// slice
const scannerSlice = createSlice({
  name: "scanner",
  initialState,
  reducers: {
    SET_SCANNER_ENDPOINT: (state, action: PayloadAction<string>) => {
      state.serialNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleScan.pending, (state) => {
      state.scanning = true;
      state.error = undefined;
      state.response = undefined;
    });
    builder.addCase(handleScan.fulfilled, (state, action) => {
      state.scanning = false;
      state.response = action.payload;
    });
    builder.addCase(handleScan.rejected, (state, action) => {
      state.scanning = false;
      if (action.error) {
        state.error = "Could not connect to scanner";
      } else {
        state.error = action.payload as string;
      }
    });
  },
});

// Export selectors
export const selectScanner = (state: RootState) => state.scanner;
export const selectScanning = (state: RootState) => state.scanner.scanning;

// Export actions
export const { SET_SCANNER_ENDPOINT } = scannerSlice.actions;

// Export reducer
export default scannerSlice.reducer;
