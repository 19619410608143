import { Box } from "@material-ui/core";
import React from "react";
import { Query, useMutation } from "react-fetching-library";
import {
  addAccountAction,
  deleteAccountAction,
  getAccountsAction,
  updateAccountAction,
} from "../../api/endpoints/cpaService";
import AccountForm from "../../components/admin/AccountForm";
import AccountList from "../../components/admin/AccountList";
import { theme } from "../../constants/theme";
import { AccountPost } from "../../validations/entities";

const Accounts = () => {
  const [updateAccount, setUpdateAccount] = React.useState<AccountPost>();
  const addAccountQuery = useMutation(addAccountAction);
  const updateAccountQuery = useMutation(updateAccountAction);
  const deleteAccountQuery = useMutation(deleteAccountAction);

  return (
    <Query action={getAccountsAction()} initFetch={true}>
      {({ loading, error, payload, query }) => (
        <>
          <Box
            width={1 / 2}
            borderRight={`1px solid ${theme.palette.divider}`}
            px={3}
            pt={3}
          >
            <AccountForm
              defaultValues={updateAccount}
              postAccountQuery={
                updateAccount ? updateAccountQuery : addAccountQuery
              }
              onSuccess={
                updateAccount
                  ? () => {
                      setUpdateAccount(undefined);
                      query();
                    }
                  : query
              }
              onCancel={
                updateAccount
                  ? () => {
                      setUpdateAccount(undefined);
                    }
                  : undefined
              }
            />
          </Box>
          <Box width={1 / 2} borderRight={`1px solid ${theme.palette.divider}`}>
            <AccountList
              loading={loading}
              error={error}
              accounts={payload}
              onReload={query}
              deleteAccount={(accountId: string) => {
                deleteAccountQuery.mutate({ AccountId: accountId }).then(() => {
                  query();
                });
              }}
              updateAccount={setUpdateAccount}
            />
          </Box>
        </>
      )}
    </Query>
  );
};

export default Accounts;
