import { Box, Button, Typography } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React from "react";
import contact from "../../assets/images/contact_us.svg";

interface Props {
  title?: string;
  subTitle?: string;
  contactAddress?: string;
}

const Contact = ({ title, subTitle, contactAddress }: Props) => {
  return (
    <Box m={3} display="flex" flexDirection="column" alignItems="center">
      <Box m={3} width={1 / 2}>
        <img src={contact} width="100%" alt="Contact Us" />
      </Box>
      <Typography variant="h5">{title ? title : "Contact Us"}</Typography>
      {subTitle && <Typography variant="subtitle1">{subTitle}</Typography>}
      {contactAddress && (
        <Box m={3}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<Send />}
            href={`mailto:${contactAddress}`}
          >
            Send us a message
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Contact;
