import { IdToken } from "msal/lib-commonjs/IdToken";
import { AuthenticationActions, AuthenticationState } from "react-aad-msal";
import { RootState } from "../configureStore";

// Interface

interface Account {
  accountIdentifier: string;
  homeAccountIdentifier: string;
  idToken: any;
  idTokenClaims: any;
  environment: string;
}

interface Authentication {
  initializing: boolean;
  initialized: boolean;
  idToken?: IdToken | null;
  state: AuthenticationState;
  accessToken?: any;
  account: Partial<Account> | null;
  isNewUser: boolean;
  accountIdentifier?: string;
}

// Initial State
const initialState: Authentication = {
  initializing: false,
  initialized: false,
  state: AuthenticationState.Unauthenticated,
  isNewUser: false,
  account: null,
};

// Slice
const authenticationReducer = (
  state = initialState,
  action: any,
): Authentication => {
  switch (action.type) {
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: null,
      };
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload.account,
      };
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      return {
        ...state,
        idToken: null,
        accessToken: null,
        account: null,
        isNewUser: false,
      };
    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload,
      };
    default:
      return state;
  }
};
// selectors
export const selectAuthenticationAccountIdentifier = (state: RootState) =>
  state.authentication.account?.accountIdentifier;

// Export Reducer
export default authenticationReducer;
