import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { ContextType } from "react";
import { ClientContext } from "react-fetching-library";
import {
  getCustomerByIdAction,
  getRoleForObjectIdAction,
  getScannerByIdAction,
} from "../../api/endpoints/cpaService";
import { Account, Customer, Scanner } from "../../validations/entities";
import { RootState } from "../configureStore";
import { SET_SCANNER_ENDPOINT } from "./rdmSlice";

type customerConfig = {
  canProcess?: boolean;
  accounts?: Account[];
  scannerId?: string;
  scanner?: Scanner;
};

type adminConfig = { customers?: Customer[]; customer?: Customer };

// Interface
interface Config {
  role: "Customer" | "Admin" | string;
  initializing: boolean;
  initialized: boolean;
  initializingMessage?: string;
  error?: SerializedError;
  alert?: string;
  contactAdmin?: boolean;
  contactAdminMessage?: string;
  customerConfig?: customerConfig;
  adminConfig?: adminConfig;
}

// Initial State
const initialState: Config = {
  role: "Unauthorized",
  initializing: false,
  initialized: false,
};

// Initial customer state
const initCustomer: customerConfig = {
  canProcess: false,
  accounts: [],
};

// Initial admin state
const initAdmin: adminConfig = {
  customers: [],
};

// ASYNC Actions
export const InitiateApp = createAsyncThunk(
  "configuration/initiateApp",
  async (
    params: {
      clientContext: ContextType<typeof ClientContext>;
      objectId: string;
    },
    thunkApi,
  ) => {
    try {
      const user = await params.clientContext.query(
        getRoleForObjectIdAction({ ObjectId: params.objectId }),
      );
      if (user.error) {
        return thunkApi.rejectWithValue(user.errorObject);
      }
      if (user.status === 200 && user.payload) {
        switch (user.payload) {
          case "Admin":
            thunkApi.dispatch(INITIATE_ADMIN());
            return user.payload;
          case "Customer":
            thunkApi.dispatch(
              InitiateCustomer({
                clientContext: params.clientContext,
                customerId: params.objectId,
              }),
            );
            return user.payload;
          default:
            thunkApi.dispatch(
              SET_CONTACT_ADMIN({
                set: true,
                message:
                  "Looks like you're not finished getting setup on our end!",
              }),
            );
            return thunkApi.rejectWithValue(thunkApi.requestId);
        }
      } else {
        return thunkApi.rejectWithValue(
          `Unable to get your account details. Check your internet connection and try again.`,
        );
      }
    } catch (err) {
      return thunkApi.rejectWithValue(
        `Unable to get your account details. Check your internet connection and try again.`,
      );
    }
  },
);

export const InitiateCustomer = createAsyncThunk(
  "configuration/initiateCustomer",
  async (
    params: {
      clientContext: ContextType<typeof ClientContext>;
      customerId: string;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(SET_INITIALIZING_MESSAGE("Getting account details."));
    try {
      // Get accounts & scanner
      const customer = await params.clientContext.query(
        getCustomerByIdAction({ CustomerId: params.customerId }),
      );
      // Handle the getCustomer
      if (!customer.error && customer.payload && customer.status === 200) {
        thunkApi.dispatch(SET_INITIALIZING_MESSAGE("Getting scanner details."));
        // validate they have a scanner
        if (
          !customer.payload.ScannerId ||
          customer.payload.ScannerId === null
        ) {
          thunkApi.dispatch(
            SET_CONTACT_ADMIN({
              set: true,
              message: "You do not have any scanners setup for your account.",
            }),
          );
          return thunkApi.rejectWithValue(thunkApi.requestId);
        } else {
          const scanner = await params.clientContext.query(
            getScannerByIdAction({ ScannerId: customer.payload.ScannerId }),
          );
          if (!scanner.error && scanner.payload && scanner.status === 200) {
            // validate the scanner has a serial number
            if (
              !scanner.payload.serialNumber ||
              scanner.payload.serialNumber === null
            ) {
              thunkApi.dispatch(
                SET_CONTACT_ADMIN({
                  set: true,
                  message:
                    "There is a problem with the details of your scanner.",
                }),
              );
              return thunkApi.rejectWithValue(thunkApi.requestId);
            } else {
              thunkApi.dispatch(SET_CUSTOMER_SCANNER(scanner.payload));
              thunkApi.dispatch(
                SET_SCANNER_ENDPOINT(scanner.payload.serialNumber),
              );
            }
          } else {
            thunkApi.dispatch(
              SET_CONTACT_ADMIN({
                set: true,
                message: "We couldn't find a scanner setup under your account.",
              }),
            );
            return thunkApi.rejectWithValue(thunkApi.requestId);
          }
        }
        thunkApi.dispatch(
          SET_INITIALIZING_MESSAGE("Getting accounts setup for deposits."),
        );
        // validate they have accounts
        if (!customer.payload.Accounts || customer.payload.Accounts === null) {
          thunkApi.dispatch(
            SET_CONTACT_ADMIN({
              set: true,
              message: "You do not have any accounts set up to make deposits.",
            }),
          );
          return thunkApi.rejectWithValue(thunkApi.requestId);
        }
        return customer.payload;
      } else {
        thunkApi.dispatch(
          SET_CONTACT_ADMIN({
            set: true,
            message: "Unable to get your account details.",
          }),
        );
        return thunkApi.rejectWithValue(thunkApi.requestId);
      }
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  },
);

// Slice
const configSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    SET_INITIALIZING_MESSAGE: (state, action: PayloadAction<string>) => {
      state.initializingMessage = action.payload;
    },
    SET_ERROR: (state, action) => {
      state.initializing = false;
      state.error = action.payload;
    },
    SET_ADMIN: (state, action) => {
      state.role = "Admin";
      state.adminConfig = action.payload;
    },
    SET_CONTACT_ADMIN: (
      state,
      action: PayloadAction<{ set: boolean; message?: string }>,
    ) => {
      state.contactAdmin = action.payload.set;
      state.contactAdminMessage = action.payload.message;
    },
    CLEAR_CONTACT_ADMIN: (state) => {
      state.contactAdmin = undefined;
      state.contactAdminMessage = undefined;
    },
    RESET_CONFIG: (state) => {
      state.role = "Unauthorized";
      state.customerConfig = undefined;
      state.adminConfig = undefined;
    },
    SET_CUSTOMER_SCANNER: (state, action: PayloadAction<Scanner>) => {
      state.customerConfig = {
        ...state.customerConfig,
        scanner: action.payload,
      };
    },
    SET_CUSTOMER_ACCOUNTS: (state, action: PayloadAction<Account[]>) => {
      state.customerConfig = {
        ...state.customerConfig,
        accounts: action.payload,
      };
    },
    INITIATE_ADMIN: (state) => {
      state.role = "Admin";
      state.adminConfig = initAdmin;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(InitiateApp.pending, (state) => {
      state.initialized = false;
      state.initializing = true;
    });
    builder.addCase(InitiateApp.fulfilled, (state, action) => {
      state.role = action.payload;
      state.initializing = false;
      state.initialized = true;
      state.initializingMessage = undefined;
    });
    builder.addCase(InitiateApp.rejected, (state, action) => {
      state.initialized = true;
      state.initializing = false;
      state.initializingMessage = undefined;
      state.error = action.error;
    });
    builder.addCase(InitiateCustomer.pending, (state) => {
      state.role = "Customer";
      state.customerConfig = initCustomer;
    });
    builder.addCase(
      InitiateCustomer.fulfilled,
      (state, action: PayloadAction<Customer>) => {
        state.customerConfig = {
          ...state.customerConfig,
          canProcess: true,
          scannerId: action.payload.ScannerId
            ? action.payload.ScannerId
            : undefined,
          accounts: action.payload.Accounts
            ? action.payload.Accounts
            : undefined,
        };
      },
    );
    builder.addCase(InitiateCustomer.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

// Export selectors
export const selectCanProcess = (state: RootState) =>
  state.configuration.customerConfig?.canProcess;
export const selectInitialized = (state: RootState) =>
  state.configuration.initialized;
export const selectInitializing = (state: RootState) =>
  state.configuration.initializing;
export const selectInitializingMessage = (state: RootState) =>
  state.configuration.initializingMessage;
export const selectAlert = (state: RootState) => state.configuration.alert;
export const selectContactAdmin = (state: RootState) =>
  state.configuration.contactAdmin;
export const selectContactAdminMessage = (state: RootState) =>
  state.configuration.contactAdminMessage;
export const selectError = (state: RootState) => state.configuration.error;
export const selectRole = (state: RootState) => state.configuration.role;
export const selectAdmin = (state: RootState) =>
  state.configuration.adminConfig;
export const selectCustomer = (state: RootState) =>
  state.configuration.customerConfig;
export const selectCustomerAccounts = (state: RootState) =>
  state.configuration.customerConfig?.accounts;
export const selectCustomerScanner = (state: RootState) =>
  state.configuration.customerConfig?.scanner;

// Export actions
export const {
  SET_INITIALIZING_MESSAGE,
  INITIATE_ADMIN,
  SET_CUSTOMER_ACCOUNTS,
  SET_CONTACT_ADMIN,
  CLEAR_CONTACT_ADMIN,
  SET_CUSTOMER_SCANNER,
  RESET_CONFIG,
  SET_ADMIN,
  SET_ERROR,
} = configSlice.actions;

// Export Reducer
export default configSlice.reducer;
