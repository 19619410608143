import { createClient, Client, Action } from "react-fetching-library";

const requestHostInterceptor = (host: string) => (_: Client) => async (
  action: Action,
) => {
  return {
    ...action,
    endpoint: `${host}${action.endpoint}`,
  } as Action;
};

export function getApiClient() {
  return createClient({
    requestInterceptors: [requestHostInterceptor("")],
    responseInterceptors: [],
  });
}
