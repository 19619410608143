import { IconButton, Tooltip } from "@material-ui/core";
import { GridColDef } from "@material-ui/data-grid";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import { theme } from "../../constants/theme";
import { Scanner, ScannerPost } from "../../validations/entities";
import ConfirmDialog from "../common/ConfirmDialog";
import QueryDataGrid from "../common/QueryDataGrid";

interface ScannerListProps {
  loading: boolean;
  error: boolean;
  onReload: () => Promise<QueryResponse<Scanner[]>>;
  scanners?: Scanner[];
  deleteScanner: (scannerId: string) => void;
  updateScanner: React.Dispatch<React.SetStateAction<ScannerPost | undefined>>;
}

const ScannerList = React.memo(
  ({
    loading,
    error,
    onReload,
    scanners,
    deleteScanner,
    updateScanner,
  }: ScannerListProps) => {
    const [scannerId, setScannerId] = React.useState<string>();
    // handle deleting
    const initiateDelete = (customerId: string) => {
      setScannerId(customerId);
    };

    const cancelDelete = () => {
      setScannerId(undefined);
    };

    const handleDelete = (customerId: string) => {
      deleteScanner(customerId);
      setScannerId(undefined);
    };

    const columns: GridColDef[] = [
      { field: "modelNumber", headerName: "Model", flex: 0.2 },
      {
        field: "serialNumber",
        headerName: "Serial Number",
        flex: 0.33,
      },
      {
        field: "scannerId",
        renderHeader: () => {
          return <></>;
        },
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          const value = params.value;
          const model = params.getValue("modelNumber");
          const serial = params.getValue("serialNumber");
          const updateObject: ScannerPost = {
            ScannerId: value ? value.toString() : "",
            ModelNumber: model ? model.toString() : "",
            SerialNumber: serial ? serial.toString() : "",
          };
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  onClick={() => updateScanner(updateObject)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  style={{ color: theme.palette.error.main }}
                  onClick={() => initiateDelete(value ? value.toString() : "")}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        },
        flex: 0.15,
      },
    ];

    return (
      <>
        {scannerId && (
          <ConfirmDialog
            open={true}
            message={`Are you sure you want to delete this scanner? This will effect the customer the scanner is assigned to.`}
            onConfirm={() => handleDelete(scannerId)}
            onDismiss={() => cancelDelete()}
          />
        )}
        <QueryDataGrid
          loading={loading}
          error={error}
          onReload={onReload}
          payload={scanners}
          columns={columns}
        />
      </>
    );
  },
);

export default ScannerList;
