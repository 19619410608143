import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { Overrides } from "@material-ui/core/styles/overrides";

// color palette
export const palette = {
  primary: "#074066",
  secondary: "#015987",
  tertiary: "#e8eff5",
  error: "#e0623a",
  warning: "#ffb71b",
  success: "#407555",
  border: "#d8dcde",
  secondary_two: "#b1b1b1",
  tertiary_two: "#d9d8d6",
  text_primary: "rgba(0, 0, 0, 0.87)",
  text_secondary: "rgba(0, 0, 0, 0.54)",
  text_disabled: "rgba(0, 0, 0, 0.38)",
  text_hint: "rgba(0, 0, 0, 0.38)",
  divider: "rgba(151, 151, 151, 0.12)",
  action_active: "rgba(216, 216, 216, 0.18)",
  action_hover: "rgba(0, 0, 0, 0.04)",
  action_hover_opacity: 0.04,
  action_selected: "rgba(0, 0, 0, 0.08)",
  action_selected_opacity: 0.08,
  action_disabled: "rgba(0, 0, 0, 0.26)",
  action_disabled_background: "rgba(0, 0, 0, 0.12)",
  action_disabled_opacity: 0.38,
  action_focus: "rgba(0, 0, 0, 0.12)",
  action_focus_opacity: 0.12,
  action_activated_opacity: 0.12,
  common_black: "#000",
  common_white: "#fff",
  common_gray: "#f9f9f9",
  bluish_grey: "#808f99",
  pale_grey: "#e7e8ef",
  blueberry: "#353976",
  navy: "#062539",
};

export const LightPalette: PaletteOptions = {
  type: "light",
  common: {
    black: palette.common_black,
    white: palette.common_white,
  },
  primary: {
    main: palette.primary,
  },
  secondary: {
    main: palette.secondary,
  },
  error: {
    main: palette.error,
  },
  warning: {
    main: palette.warning,
  },
  info: {
    main: palette.tertiary,
  },
  success: {
    main: palette.success,
  },
  text: {
    primary: palette.text_primary,
    secondary: palette.text_secondary,
    disabled: palette.text_disabled,
    hint: palette.text_hint,
  },
  divider: palette.divider,
  action: {
    active: palette.action_active,
    hover: palette.action_hover,
    hoverOpacity: palette.action_hover_opacity,
    selected: palette.action_selected,
    selectedOpacity: palette.action_selected_opacity,
    disabled: palette.action_disabled,
    disabledBackground: palette.action_disabled_background,
    disabledOpacity: palette.action_disabled_opacity,
    focus: palette.action_focus,
    focusOpacity: palette.action_focus_opacity,
    activatedOpacity: palette.action_activated_opacity,
  },
  background: {
    default: palette.common_white,
    paper: palette.common_white,
  },
};

// Font Settings
const lineHeights = {};
const letterSpacings = {};
const fontFamily = {
  body: "'Lato', sans-serif",
  headings: "'Lato', sans-serif",
};
export const fontSizes = [
  "0.5rem", //8
  "0.625rem", //10
  "0.75rem", //12
  "0.875rem", //14
  "1rem", //16
  "1.125rem", //18
  "1.25rem", //20
  "1.5rem", //24
  "1.75rem",
  "2rem",
  "2.5rem",
  "3rem",
];
export const fontWeights = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
};
// Headings are based on our Zeplin Design
const fontVariants = {
  h1: {
    fontFamily: fontFamily.headings,
    fontWeight: fontWeights.bold,
  },
  h2: {
    fontFamily: fontFamily.headings,
    fontWeight: fontWeights.regular,
  },
  h3: {
    fontFamily: fontFamily.headings,
    fontWeight: fontWeights.regular,
  },
  h4: {
    fontFamily: fontFamily.headings,
    fontWeight: fontWeights.regular,
  },
  h5: {
    fontFamily: fontFamily.headings,
    fontWeight: fontWeights.regular,
  },
  h6: {
    fontFamily: fontFamily.headings,
    fontWeight: fontWeights.regular,
  },
  subtitle1: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.regular,
  },
  subtitle2: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.bold,
  },
  body1: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.regular,
  },
  body2: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.regular,
  },
  button: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.bold,
  },
  caption: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.regular,
  },
  overline: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeights.regular,
  },
};

export const FontSettings = {
  fontFamily,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  fontVariants,
};

// space settings
export const space = [0, 4, 8, 16, 20, 32, 48, 64, 96, 112, 128, 256, 512];

// size settings
export const sizes = [8, 16, 32, 64, 128, 256, 512, 768, 1024, 1536, 690];

// breakpoints
export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// border settings
const borderColor = palette.border;
const borderRadius = 8;

export const BorderSettings = { borderColor, borderRadius };

// Standard toolbar height
export const toolbarHeight = {
  sm: 64,
  md: 54,
};

// Fixed toolbar height
export const fixedToolbarHeight = "85px";

// Overrides
export const BrandOverrides: Overrides = {
  MuiCssBaseline: {
    "@global": {
      html: {
        WebkitFontSmoothing: "auto",
      },
      body: {
        "& #root": {
          height: `calc(100vh - ${fixedToolbarHeight})`,
        },
      },
    },
  },
  // app bar overrides
  MuiAppBar: {
    root: {
      // fixed height
      height: fixedToolbarHeight,
      boxShadow: "none",
      borderBottom: `1px solid ${borderColor}`,
    },
  },
  MuiFormControlLabel: {
    root: {
      fontSize: "12px",
    },
  },
  // button overrides
  MuiButton: {
    root: {
      textTransform: "capitalize",
    },
    sizeLarge: {
      padding: space[3],
    },
  },
  MuiButtonBase: {},
  MuiDrawer: {},
  MuiFilledInput: {
    root: {
      color: palette.text_primary,
      backgroundColor: palette.common_white,
      borderRadius: "8px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      border: `1px solid ${palette.border}`,
      "&$focused": {
        borderColor: palette.common_white,
      },
      "& .MuiInputAdornment-filled .MuiInputAdornment-positionEnd .MuiIconButton-edgeEnd": {
        backgroundColor: palette.tertiary,
      },
    },
    underline: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    input: {
      "& .Mui-error": {},
      "&$focused": {},
    },
  },
  // input overrides
  MuiInput: {},
  MuiInputBase: {
    root: {
      color: palette.text_primary,
      backgroundColor: palette.common_white,
    },
  },
  MuiOutlinedInput: {},
  MuiSelect: {
    icon: {
      color: palette.primary,
    },
  },
  // list overrides
  MuiList: {},
  MuiListItem: {
    root: {
      "&.Mui-selected": {
        backgroundColor: palette.primary,
        "&:hover": {
          background: palette.primary,
        },
      },
    },
  },
  MuiMenuItem: {
    root: {
      "&.Mui-selected": {
        backgroundColor: palette.primary,
        color: palette.common_white,
        "&:hover": {
          background: palette.primary,
        },
      },
    },
  },
  MuiListItemText: {},
  MuiListItemIcon: {
    root: {
      minWidth: "auto",
      marginRight: space[3],
    },
  },
  MuiTableCell: {
    root: {
      padding: "8px 16px",
    },
  },
  // toolbar
  MuiToolbar: {
    root: {
      // fixed height
      height: fixedToolbarHeight,
    },
  },
};
