import { Box, Grid } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { SideBar } from "./components/common";
import { AdminRoutes, CustomerRoutes, InvalidRoutes } from "./constants/routes";

const AdminRoute = () => (
  <Box height={1}>
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={3} spacing={0}>
        <SideBar routesList={AdminRoutes} />
      </Grid>
      <Grid container item xs={9} lg={8}>
        <SnackbarProvider maxSnack={3}>
          <Switch>
            {AdminRoutes.map((item) => (
              <Route {...item.route} />
            ))}
          </Switch>
        </SnackbarProvider>
      </Grid>
    </Grid>
  </Box>
);

const CustomerRoute = () => (
  <Grid container>
    <Grid item xs={2} />
    <Grid item xs={8}>
      <SnackbarProvider maxSnack={3}>
        <Switch>
          {CustomerRoutes.map((item) => (
            <Route {...item.route} />
          ))}
        </Switch>
      </SnackbarProvider>
    </Grid>
    <Grid item xs={2}></Grid>
  </Grid>
);

const InvalidRoute = () => (
  <Switch>
    {InvalidRoutes.map((item) => (
      <Route {...item.route} />
    ))}
  </Switch>
);

interface RoutesProps {
  variant: "Customer" | "Admin" | string;
}

const Routes = ({ variant }: RoutesProps) => {
  switch (variant) {
    case "Customer":
      return <CustomerRoute />;
    case "Admin":
      return <AdminRoute />;
    default:
      return <InvalidRoute />;
  }
};

export default Routes;
