import { combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from "./authenticationSlice";
import configurationReducer from "./configSlice";
import scannerReducer from "./rdmSlice";
import checksReducer from "./checksSlice";

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  configuration: configurationReducer,
  scanner: scannerReducer,
  checks: checksReducer,
});
