import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

interface ConfirmDialogProps {
  open: boolean;
  message: string;
  onConfirm: (args0: any) => void;
  onDismiss: (args0: any) => void;
}

const ConfirmDialog = ({
  open,
  message,
  onConfirm,
  onDismiss,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onConfirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
