import { Redirect, RouteProps } from "react-router-dom";
import { NotFound } from "../components/common";
import Contact from "../components/common/Contact";
import Accounts from "../pages/admin/Accounts";
import Customers from "../pages/admin/Customers";
import Scanners from "../pages/admin/Scanners";
import ScannerTest from "../pages/admin/ScannerTest";
import Batches from "../pages/customers/Batches";
import Processing from "../pages/customers/Processing";

export const AdminRoutes: { title?: string; route: RouteProps }[] = [
  {
    route: {
      path: "/",
      component: () => {
        return <Redirect to="/customers" />;
      },
      exact: true,
    },
  },
  {
    title: "Customers",
    route: {
      path: "/customers",
      component: Customers,
      exact: true,
    },
  },
  {
    title: "Scanners",
    route: {
      path: "/scanners",
      component: Scanners,
      exact: true,
    },
  },
  {
    title: "Accounts",
    route: {
      path: "/accounts",
      component: Accounts,
      exact: true,
    },
  },
  {
    title: "Scanner Test",
    route: {
      path: "/scanner-test",
      component: ScannerTest,
      exact: true,
    },
  },
  { route: { component: NotFound } },
];

export const CustomerRoutes: { title?: string; route: RouteProps }[] = [
  {
    route: {
      path: "/",
      component: () => {
        return <Redirect to="/batches" />;
      },
      exact: true,
    },
  },
  {
    title: "Batches",
    route: { path: "/batches", component: Batches, exact: true },
  },
  {
    title: "Processing",
    route: { path: "/processing", component: Processing, exact: true },
  },
  { route: { component: NotFound } },
];

export const InvalidRoutes: { title?: string; route: RouteProps }[] = [
  {
    title: "Invalid User",
    route: {
      path: "*",
      component: () => (
        <Contact
          title="Looks like you're not finished getting setup."
          subTitle="You do not have an assigned role."
          contactAddress="support@bocbanking.com"
        />
      ),
    },
  },
];
