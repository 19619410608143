import { Box } from "@material-ui/core";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useSelector } from "react-redux";
import { getBatchesByCustomerIdAction } from "../../api/endpoints/cpaService";
import BatchList from "../../components/customer/BatchList";
import { selectAuthenticationAccountIdentifier } from "../../redux/slices/authenticationSlice";

const Batches = () => {
  const account = useSelector(selectAuthenticationAccountIdentifier);
  const { loading, payload, error, query } = useQuery(
    getBatchesByCustomerIdAction({ CustomerId: account ? account : "" }),
  );

  return (
    <Box mt={3}>
      <BatchList
        loading={loading}
        error={error}
        batches={payload}
        onReload={query}
      />
    </Box>
  );
};

export default Batches;
