import { CssBaseline, LinearProgress, ThemeProvider } from "@material-ui/core";
import React from "react";
import AzureAD, { AuthenticationState, MsalAuthProvider } from "react-aad-msal";
import { ClientContextProvider } from "react-fetching-library";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getApiClient } from "./api/apiClient";
import Unauthorized from "./components/common/Unauthorized";
import { b2cAuthProvider } from "./constants/authProvider";
import { theme } from "./constants/theme";
import AppContainer from "./containers/AppContainer";
import { rootStore } from "./redux/configureStore";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Provider store={rootStore}>
          <BrowserRouter>
            <AzureAD
              provider={b2cAuthProvider}
              reduxStore={rootStore}
              forceLogin
            >
              {({ login, logout, authenticationState }: MsalAuthProvider) => {
                switch (authenticationState) {
                  case AuthenticationState.Unauthenticated:
                    return <Unauthorized handleLogin={login} />;
                  case AuthenticationState.Authenticated:
                    return (
                      <ClientContextProvider client={getApiClient()}>
                        <AppContainer login={login} logout={logout} />
                      </ClientContextProvider>
                    );
                  case AuthenticationState.InProgress:
                    return <LinearProgress color="secondary" />;
                }
              }}
            </AzureAD>
          </BrowserRouter>
        </Provider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
