import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import login from "../../assets/images/login.svg";

interface Props {
  handleLogin?: () => void;
}

const Unauthorized = ({ handleLogin }: Props) => {
  return (
    <Box m={3} display="flex" flexDirection="column" alignItems="center">
      <Box m={3} width={1 / 2}>
        <img src={login} width="100%" alt="Man opening door" />
      </Box>
      <Typography variant="h5">403: Unauthorized</Typography>
      <Typography variant="subtitle1">
        Looks like you don't have access to this resource.
      </Typography>
      {handleLogin && (
        <Box m={3} width={1 / 4}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="medium"
            onClick={handleLogin}
          >
            Login
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Unauthorized;
