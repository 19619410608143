import { Box } from "@material-ui/core";
import React from "react";
import CustomerList from "../../components/admin/CustomerList";
import CustomerForm from "../../components/admin/CustomerForm";
import { theme } from "../../constants/theme";
import {
  addCustomerAction,
  deleteCustomerAction,
  getCustomersAction,
  updateCustomerAction,
} from "../../api/endpoints/cpaService";
import { Query, useMutation } from "react-fetching-library";
import { CustomerPost } from "../../validations/entities";

const Customers = () => {
  const [updateCustomer, setUpdateCustomer] = React.useState<
    Omit<Partial<CustomerPost>, "ObjectId">
  >();
  const postCustomerQuery = useMutation(addCustomerAction);
  const updateCustomerQuery = useMutation(updateCustomerAction);
  const deleteCustomerQuery = useMutation(deleteCustomerAction);

  return (
    <Query action={getCustomersAction()} initFetch={true}>
      {({ loading, error, payload, query }) => (
        <>
          <Box
            width={1 / 2}
            borderRight={`1px solid ${theme.palette.divider}`}
            px={3}
            pt={3}
          >
            <CustomerForm
              postCustomerQuery={postCustomerQuery}
              updateCustomerQuery={updateCustomerQuery}
              onSuccess={query}
              defaultValues={updateCustomer}
              onCancel={
                updateCustomer
                  ? () => {
                      setUpdateCustomer(undefined);
                    }
                  : undefined
              }
            />
          </Box>
          <Box width={1 / 2} borderRight={`1px solid ${theme.palette.divider}`}>
            <CustomerList
              loading={loading}
              error={error}
              onReload={query}
              customers={payload}
              deleteCustomer={(customerId: string) => {
                deleteCustomerQuery
                  .mutate({ CustomerId: customerId })
                  .then(() => {
                    query();
                  });
              }}
              updateCustomer={setUpdateCustomer}
            />
          </Box>
        </>
      )}
    </Query>
  );
};

export default Customers;

/*      */
