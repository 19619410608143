const servicePaths = {
  cpaService: "CPAService",
  scannerService: "/SCI/7.0/sci.esp?",
};

const apimURL = `${process.env.REACT_APP_APIM_BASE_URL}`;
const scannerEndpointURL = `https://rd?`;

const ApiConfig = {
  endpoints: {
    CPAService: {
      // GET
      getCustomers: apimURL + servicePaths.cpaService + "/GetCustomers",
      getAccounts: apimURL + servicePaths.cpaService + "/GetAccounts",
      getAccountById: apimURL + servicePaths.cpaService + "/GetAccountById",
      getBatches: apimURL + servicePaths.cpaService + "/GetBatches",
      getBatchById: apimURL + servicePaths.cpaService + "/GetBatchById",
      getBatchesByCustomerId:
        apimURL + servicePaths.cpaService + "/GetBatchesByCustomerId",
      getCustomerById: apimURL + servicePaths.cpaService + "/GetCustomerById",
      getRoleForObjectId:
        apimURL + servicePaths.cpaService + "/GetRoleForObjectId",
      getScanners: apimURL + servicePaths.cpaService + "/GetScanners",
      getScannerById: apimURL + servicePaths.cpaService + "/GetScannerById",
      // POST
      addCustomer: apimURL + servicePaths.cpaService + "/AddCustomer",
      updateCustomer: apimURL + servicePaths.cpaService + "/UpdateCustomer",
      deleteCustomer: apimURL + servicePaths.cpaService + "/DeleteCustomer",
      addScanner: apimURL + servicePaths.cpaService + "/AddScanner",
      updateScanner: apimURL + servicePaths.cpaService + "/UpdateScanner",
      deleteScanner: apimURL + servicePaths.cpaService + "/DeleteScanner",
      addAccount: apimURL + servicePaths.cpaService + "/AddAccount",
      updateAccount: apimURL + servicePaths.cpaService + "/UpdateAccount",
      deleteAccount: apimURL + servicePaths.cpaService + "/DeleteAccount",
      uploadCheck: apimURL + servicePaths.cpaService + "/UploadCheck",
      addBatch: apimURL + servicePaths.cpaService + "/AddBatch",
    },
    ScannerService: {
      // POST
      claimScanner:
        scannerEndpointURL +
        servicePaths.scannerService +
        "Function=ClaimScanner&UserId=",
      getResponseXml:
        scannerEndpointURL +
        servicePaths.scannerService +
        "Function=GetResponseXml&UserId=",
      getState:
        scannerEndpointURL +
        servicePaths.scannerService +
        "Function=GetState&UserId=",
      releaseScanner:
        scannerEndpointURL +
        servicePaths.scannerService +
        "Function=ReleaseScanner&UserId=",
      startOperation:
        scannerEndpointURL +
        servicePaths.scannerService +
        "Function=StartOperation&StartType=ScanSingleItem&UserId=",
    },
  },
};

export default ApiConfig;
