import { Fade } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { ClientContext } from "react-fetching-library";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppBar } from "../components/common";
import AppBackdrop from "../components/common/AppBackdrop";
import Contact from "../components/common/Contact";
import { selectAuthenticationAccountIdentifier } from "../redux/slices/authenticationSlice";
import {
  InitiateApp,
  selectCanProcess,
  selectContactAdmin,
  selectContactAdminMessage,
  selectInitialized,
  selectInitializing,
  selectInitializingMessage,
  selectRole,
} from "../redux/slices/configSlice";
import Routes from "../Routes";

interface AppContainerProps {
  logout: (args0: any) => void;
  login: (args0: any) => void;
}

const AppContainer = ({ logout }: AppContainerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const context = useContext(ClientContext);
  const initializing = useSelector(selectInitializing);
  const initialized = useSelector(selectInitialized);
  const initializingMessage = useSelector(selectInitializingMessage);
  const role = useSelector(selectRole);
  const canProcess = useSelector(selectCanProcess);
  const accountIdentifier = useSelector(selectAuthenticationAccountIdentifier);
  const contactAdmin = useSelector(selectContactAdmin);
  const contactAdminMessage = useSelector(selectContactAdminMessage);

  useEffect(() => {
    if (accountIdentifier && !initialized) {
      dispatch(
        InitiateApp({ clientContext: context, objectId: accountIdentifier }),
      );
    }
  }, [accountIdentifier, context, dispatch, initialized]);

  return (
    <>
      {initializing && (
        <AppBackdrop
          open={true}
          message={
            initializingMessage
              ? initializingMessage
              : "Gathering account information..."
          }
        />
      )}
      {!initializing && initialized && (
        <Fade in={true} timeout={1000}>
          <>
            <AppBar
              variant={role}
              logout={logout}
              initiateBatch={() => history.push("/processing")}
              canProcess={canProcess}
            />
            {!contactAdmin ? (
              <Routes variant={role} />
            ) : (
              <Contact
                title="Something is not quite right! Contact us to get it fixed."
                subTitle={contactAdminMessage}
                contactAddress="support@bocbanking.com"
              />
            )}
          </>
        </Fade>
      )}
    </>
  );
};

export default AppContainer;
