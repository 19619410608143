import React from "react";
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Logo } from "../brand/icons";
import { theme } from "../../constants/theme";
import { PowerSettingsNew } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
interface AppBarProps {
  variant: "Admin" | "Customer" | string;
  logout: (args0: any) => void;
  initiateBatch: (args0: any) => void;
  canProcess?: boolean;
}

const AppBar = ({
  variant,
  logout,
  initiateBatch,
  canProcess,
}: AppBarProps) => {
  const location = useLocation();
  return (
    <MuiAppBar
      position="static"
      color={variant === "Customer" ? "transparent" : "primary"}
    >
      <Toolbar>
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          spacing={5}
        >
          <Grid container item xs={2}>
            <Box textAlign="right" width={1} height={1}>
              <Logo
                htmlColor={
                  variant === "Customer"
                    ? theme.palette.primary.main
                    : theme.palette.primary.contrastText
                }
              />
            </Box>
          </Grid>
          <Grid item container xs={1}>
            <Box maxWidth="80px">
              <Typography
                align="left"
                variant="body2"
                style={{
                  color:
                    variant === "Customer"
                      ? theme.palette.text.disabled
                      : theme.palette.primary.contrastText,
                  fontSize: theme.typography.pxToRem(12),
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                Remote Batch Deposit
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5} />
          {canProcess && location.pathname !== "/processing" ? (
            <Grid item xs={2} style={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={initiateBatch}
              >
                Upload Batch
              </Button>
            </Grid>
          ) : (
            <Grid item xs={2} />
          )}
          <Grid item xs={1}>
            <Button
              startIcon={<PowerSettingsNew />}
              variant="text"
              style={{ color: theme.palette.error.main }}
              onClick={logout}
            >
              Logout
            </Button>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
