import {
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Scanner } from "@material-ui/icons";
import React, { useContext } from "react";
import { ClientContext, ClientContextProvider } from "react-fetching-library";
import { useDispatch, useSelector } from "react-redux";
import { getState } from "../../api/endpoints/rdmService";
import { getApiClient } from "../../api/rdmClient";
import { useApiQuery } from "../../hooks/useApiQuery";
import { selectAuthenticationAccountIdentifier } from "../../redux/slices/authenticationSlice";
import {
  handleScan,
  selectScanner,
  SET_SCANNER_ENDPOINT,
} from "../../redux/slices/rdmSlice";

const Tests = () => {
  const [scanner, setScanner] = React.useState<string>("");
  const dispatch = useDispatch();
  const client = useContext(ClientContext);
  const identity = useSelector(selectAuthenticationAccountIdentifier);
  const userId = identity ? identity : "";
  const { serialNumber, scanning, error, response } = useSelector(
    selectScanner,
  );
  const scannerStatus = useApiQuery(getState({ UserId: userId }));

  return (
    <>
      <Box p={3} display="flex" flexDirection="column">
        <Box>
          <Typography>Scanner State</Typography>
          {JSON.stringify(scannerStatus)}
        </Box>
        <Box>
          <Button
            onClick={() =>
              dispatch(
                handleScan({
                  clientContext: client,
                  userId,
                }),
              )
            }
            startIcon={<Scanner />}
            color="secondary"
            variant="contained"
          >
            Scan
          </Button>
        </Box>
        <List>
          <ListItem>
            <ListItemText
              primary={"Serial Number"}
              secondary={JSON.stringify(serialNumber)}
            />
            <ListItemSecondaryAction>
              <Box display="inline-flex" alignItems="center">
                <TextField
                  id="ScannerSerial"
                  name="ScannerSerial"
                  placeholder="Serial Number"
                  margin="dense"
                  value={scanner}
                  onChange={(e) => {
                    setScanner(e.target.value);
                  }}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    dispatch(SET_SCANNER_ENDPOINT(scanner));
                  }}
                >
                  Set
                </Button>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={"Scanning"}
              secondary={JSON.stringify(scanning)}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={"Error"} secondary={JSON.stringify(error)} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={"Response"}
              secondary={JSON.stringify(response)}
            />
          </ListItem>
        </List>
      </Box>
    </>
  );
};

const ScannerTest = () => {
  const client = getApiClient();
  return (
    <Box width={1} display="flex" flexDirection="Column">
      <ClientContextProvider client={client}>
        <Tests />
      </ClientContextProvider>
    </Box>
  );
};

export default ScannerTest;
