import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { ArrowDropDownCircle } from "@material-ui/icons";
import React from "react";
import { theme } from "../../constants/theme";

const iconComponent = (props: { className: string }) => {
  return <ArrowDropDownCircle className={props.className} />;
};

const ScannerModelSelect = ({
  formControlProps,
  selectProps,
  helperText,
}: {
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  helperText?: string;
}) => {
  return (
    <FormControl {...formControlProps} size="small">
      <InputLabel>Select Model</InputLabel>
      <Select {...selectProps} IconComponent={iconComponent}>
        <MenuItem key={`model-1`} value={`EC9100`}>
          EC9100
        </MenuItem>
        <MenuItem key={`model-2`} value={`EC9600`}>
          EC9600
        </MenuItem>
      </Select>
      {helperText && (
        <FormHelperText
          margin="dense"
          style={{ fontSize: theme.typography.pxToRem(10) }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ScannerModelSelect;
