import { Box, Button } from "@material-ui/core";
import { GridColDef, DataGridProps, GridToolbar } from "@material-ui/data-grid";
import { Refresh } from "@material-ui/icons";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import { DataGrid, ErrorOverlay } from "../brand/DataGrids";

interface QueryDataGridProps {
  loading: boolean;
  error: boolean;
  onReload: () => Promise<QueryResponse<any[]>>;
  payload?: any[];
  columns: GridColDef[];
  dataGridProps?: DataGridProps;
}

const QueryDataGrid = ({
  loading,
  error,
  onReload,
  payload,
  columns,
  dataGridProps,
}: QueryDataGridProps) => {
  const mappedRows = payload
    ? payload.map((item, index) => {
        return {
          id: index,
          ...item,
        };
      })
    : [];
  return (
    <DataGrid
      components={{
        Toolbar: GridToolbar,
        ErrorOverlay: () => (
          <ErrorOverlay>
            <Box m={3}>
              <Button
                color="secondary"
                startIcon={<Refresh />}
                onClick={onReload}
              >
                Retry
              </Button>
            </Box>
          </ErrorOverlay>
        ),
      }}
      error={error ? error : undefined}
      loading={loading}
      rows={mappedRows}
      columns={columns}
      autoHeight
      pageSize={15}
      {...dataGridProps}
    />
  );
};

export default QueryDataGrid;
