import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const Logo = (props: SvgIconProps) => (
  <SvgIcon
    style={{ width: "auto", height: "auto", maxWidth: "90px" }}
    viewBox="0 0 79.6 43.7"
    {...props}
  >
    <path
      fill="inherit"
      d="M30.21,42.17c0,2-2.73,1.43-4,1.51V38.54h2.11c1.34-.12,2.34,1.55,1.23,2.47A1.31,1.31,0,0,1,30.21,42.17Zm-2.78-2.53v.91c.39,0,1.37.2,1.35-.45S27.82,39.68,27.43,39.64Zm1,2.94a.5.5,0,1,0,0-1h-1v1Z"
    />
    <path
      fill="inherit"
      d="M36.89,43.68l-.27-.81h-2l-.27.81H33l1.84-5.14H36.4l1.84,5.14ZM35,41.77h1.26l-.63-1.88Z"
    />
    <path
      fill="inherit"
      d="M44.38,38.54h1.24v5.14h-.93l-2.08-2.79v2.79H41.37V38.54h.93l2.08,2.79Z"
    />
    <path
      fill="inherit"
      d="M53.68,43.68H52.27L50.55,41.3v2.38H49.31V38.54h1.24v2.23l1.65-2.23h1.4L51.78,41Z"
    />
    <path
      fill="inherit"
      d="M14.41,6.53a14.17,14.17,0,0,0-8.36,2.7V0H0V35.59H6.05V33.07c9.3,6.88,23-.25,22.83-11.92A14.56,14.56,0,0,0,14.41,6.53Zm0,23.11a8.45,8.45,0,0,1-8.36-7.51,7.22,7.22,0,0,1,0-2c1.5-10.51,16.46-9.68,16.77,1A8.46,8.46,0,0,1,14.41,29.64Z"
    />
    <path
      fill="inherit"
      d="M39.77,12.67,26.25,26.32a14.64,14.64,0,0,0,2.18,3.88L39.77,18.75Z"
    />
    <path
      fill="inherit"
      d="M25.32,21.17,39.77,6.59A14.52,14.52,0,0,0,25.32,21.17Z"
    />
    <path
      fill="inherit"
      d="M39.77,24.83l-8.24,8.32a14.61,14.61,0,0,0,4.06,2l4.18-4.22Z"
    />
    <path
      fill="inherit"
      d="M39.77,12.67,53.29,26.32a14.64,14.64,0,0,1-2.18,3.88L39.77,18.75Z"
    />
    <path
      fill="inherit"
      d="M54.22,21.17,39.77,6.59A14.52,14.52,0,0,1,54.22,21.17Z"
    />
    <path
      fill="inherit"
      d="M39.77,24.83,48,33.15a14.47,14.47,0,0,1-4.06,2l-4.18-4.22Z"
    />
    <path
      fill="inherit"
      d="M73.28,24.39c-3.55,8.48-16.09,6.06-16.2-3.25.11-9.3,12.65-11.72,16.2-3.24H79.6A14.53,14.53,0,0,0,65.5,6.53c-19.2.8-19.2,28.43,0,29.23A14.53,14.53,0,0,0,79.6,24.39Z"
    />
    <rect fill="inherit" x="0.2" y="40.41" width="21.75" height="1.34" />
    <rect fill="inherit" x="57.41" y="40.41" width="21.75" height="1.34" />
  </SvgIcon>
);
