import { Box, Typography } from "@material-ui/core";
import React from "react";
import lost from "../../assets/images/lost.svg";

const NotFound = () => {
  return (
    <Box m={3} display="flex" flexDirection="column" alignItems="center">
      <Box m={3} width={1 / 2}>
        <img src={lost} width="100%" alt="Man looking at map" />
      </Box>
      <Typography variant="h5">404: Not Found</Typography>
      <Typography variant="subtitle1">
        Not sure what you are looking for, but it looks like it doesn't exist!
      </Typography>
    </Box>
  );
};

export default NotFound;
