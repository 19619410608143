import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { theme } from "../../constants/theme";
import FormActionsContainer from "../../containers/FormActionsContainer";
import { Account, AccountPost } from "../../validations/entities";
import { ACCOUNT_SCHEMA } from "../../validations/schemas";
import { PrimaryButton, CancelButton } from "../brand/Buttons";
import FormDialog from "../common/FormDialog";

interface AccountFormProps {
  postAccountQuery: {
    loading: boolean;
    error: boolean;
    status?: number;
    mutate: (action: AccountPost) => Promise<QueryResponse<Account>>;
  };
  onSuccess: () => void;
  defaultValues?: AccountPost;
  onCancel?: () => void;
}

const AccountForm = ({
  postAccountQuery,
  onSuccess,
  defaultValues,
  onCancel,
}: AccountFormProps) => {
  const [data, setData] = React.useState<AccountPost>();
  const [isUpdate, setIsUpdate] = React.useState(false);
  const methods = useForm({
    resolver: yupResolver(ACCOUNT_SCHEMA),
    defaultValues: {
      AccountNum: "",
      AccountNickname: "",
      IsLegacy: false,
    },
  });
  const handleReset = () => {
    methods.reset({
      AccountNum: "",
      AccountNickname: "",
      IsLegacy: false,
    });
  };
  const handleCloseFormDialog = (error: boolean) => {
    setData(undefined);
    if (!error) {
      handleReset();
    }
  };
  const onSubmit = (data: any) => {
    const newAccount: AccountPost = {
      AccountId: defaultValues?.AccountId,
      AccountNum: data.AccountNum,
      AccountNickname: data.AccountNickname,
      IsLegacy: data.IsLegacy,
    };
    setData(newAccount);
    postAccountQuery.mutate(newAccount).then((res) => {
      if (res.status === 200) {
        onSuccess();
      }
    });
  };

  React.useEffect(() => {
    if (defaultValues) {
      setIsUpdate(true);
      methods.setValue("AccountNum", defaultValues.AccountNum);
      methods.setValue("AccountNickname", defaultValues.AccountNickname);
      methods.setValue("IsLegacy", defaultValues.IsLegacy);
    }
  }, [defaultValues, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {data && (
          <FormDialog
            error={postAccountQuery.error}
            errorMessage={`Account could not be ${
              isUpdate ? "Updated" : "Created"
            }. Status Code: ${postAccountQuery.status}`}
            success={postAccountQuery.status === 200}
            successMessage={`Account has been ${
              isUpdate ? "updated" : "created"
            }.`}
            loading={postAccountQuery.loading}
            loadingMessage={`${isUpdate ? "Updating" : "Adding"} Account...`}
            onClose={() => handleCloseFormDialog(postAccountQuery.error)}
            onReload={() => postAccountQuery.mutate(data)}
          />
        )}
        <Box>
          <Typography variant="h6" color="primary">
            Account Details
          </Typography>
          <Box borderTop={`2px solid ${theme.palette.primary.main}`}>
            <Controller
              as={TextField}
              control={methods.control}
              defaultValue=""
              fullWidth
              label="Account Number"
              margin="dense"
              name="AccountNum"
              variant="filled"
              error={methods.errors?.AccountNum && true}
              helperText={
                methods.errors?.AccountNum && methods.errors.AccountNum.message
              }
            />
            <Controller
              as={TextField}
              control={methods.control}
              defaultValue=""
              fullWidth
              label="Account Nickname"
              margin="dense"
              name="AccountNickname"
              variant="filled"
              error={methods.errors?.AccountNickname && true}
              helperText={
                methods.errors?.AccountNickname && methods.errors.AccountNickname.message
              }
            />
            <FormControlLabel
              control={
                <Controller
                  name="IsLegacy"
                  control={methods.control}
                  render={(props) => (
                    <Checkbox
                      onChange={(e) => props.onChange(e.target.checked)}
                      checked={props.value}
                    />
                  )}
                />
              }
              label="Is a Legacy Account"
            />
          </Box>
        </Box>
        <FormActionsContainer
          boxSettings={{
            justifyContent: "left",
          }}
          fullWidthButtons={true}
          forwardButton={
            <PrimaryButton
              size="large"
              fullWidth
              type="submit"
              disabled={methods.formState.isSubmitting}
            >
              {defaultValues ? "Update" : "Submit"}
            </PrimaryButton>
          }
          backwardButton={
            <CancelButton
              size="large"
              fullWidth
              disabled={methods.formState.isSubmitting}
              onClick={
                onCancel
                  ? () => {
                      onCancel();
                      handleReset();
                    }
                  : () => handleReset()
              }
            >
              Cancel
            </CancelButton>
          }
        />
      </form>
    </FormProvider>
  );
};

export default AccountForm;
