import {
  Button,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectProps,
  Typography,
} from "@material-ui/core";
import { ArrowDropDownCircle } from "@material-ui/icons";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import { theme } from "../../constants/theme";
import { Scanner } from "../../validations/entities";

const iconComponent = (props: { className: string }) => {
  return <ArrowDropDownCircle className={props.className} />;
};

const ScannerSelect = ({
  loading,
  error,
  onReload,
  scanners,
  formControlProps,
  selectProps,
  helperText,
}: {
  loading: boolean;
  error: boolean;
  onReload: () => Promise<QueryResponse<Scanner[]>>;
  scanners?: Scanner[];
  formControlProps?: FormControlProps;
  selectProps?: SelectProps;
  helperText?: string;
}) => {
  return (
    <FormControl {...formControlProps} size="small">
      {loading && <LinearProgress title="Getting Scanners" />}
      {error && (
        <>
          <Typography>There was a problem getting the scanners.</Typography>
          <Button color="secondary" onClick={onReload}>
            Retry
          </Button>
        </>
      )}
      {!loading && !error && scanners && (
        <>
          <InputLabel>Select Scanner</InputLabel>
          <Select {...selectProps} IconComponent={iconComponent}>
            {scanners.map((scanner, index) => (
              <MenuItem
                key={`scanner-${index}`}
                value={scanner.scannerId}
              >{`M: ${scanner.modelNumber} - SN: ${scanner.serialNumber}`}</MenuItem>
            ))}
          </Select>
        </>
      )}
      {helperText && (
        <FormHelperText
          margin="dense"
          style={{ fontSize: theme.typography.pxToRem(10) }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default ScannerSelect;
