import { Tooltip, IconButton } from "@material-ui/core";
import { Delete, ArrowDropDownCircle } from "@material-ui/icons";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { theme } from "../../constants/theme";
import { ProcessingCheck } from "../../validations/entities";
import { TableTextField } from "../brand/Inputs";
import { BandedTableRow, BandedTableCellLessPadding } from "../brand/Tables";
import AccountSelect from "./AccountSelect";
import CheckDisplay from "./CheckDisplay";
import {
  invalidAlphanumeric,
  invalidCurrencyMessage,
  invalidNumberMessage,
  isAlphanumeric,
  isCurrency,
  isNumber,
  validMicrLine,
} from "../../validations/helpers";

interface CheckListItemProps {
  check: ProcessingCheck;
  id: string;
  index: number;
  setAccount: (args0: any) => void;
  setAmount: (args0: any) => void;
  setNumber: (args0: any) => void;
  setPayor: (args0: any) => void;
  setMicr: (args0: any) => void;
  remove: (args0: any) => void;
}

const CheckListItem = ({
  check,
  id,
  index,
  setAccount,
  setAmount,
  setNumber,
  setPayor,
  setMicr,
  remove,
}: CheckListItemProps) => {
  const [open, setOpen] = useState(true);
  const { register, errors, control, setValue } = useFormContext();
  const toggleCheck = () => {
    setOpen(!open);
  };
  return (
    <>
      <BandedTableRow key={`checks[${index}]`}>
        <BandedTableCellLessPadding>{index + 1}</BandedTableCellLessPadding>
        <BandedTableCellLessPadding>
          <TableTextField
            name={`${id}.micr`}
            placeholder="MICR"
            fullWidth
            value={check.micr}
            inputRef={register({
              validate: (value) => {
                return validMicrLine(value) || "Invalid MICR";
              },
            })}
            onChange={(e) => {
              setMicr(e.target.value);
            }}
            size="small"
            variant="outlined"
            error={errors?.[id] && errors?.[id].micr}
            helperText={
              errors?.[id] && errors?.[id].micr && errors?.[id].micr.message
            }
            FormHelperTextProps={{
              margin: "dense",
            }}
          />
        </BandedTableCellLessPadding>
        <BandedTableCellLessPadding>
          <TableTextField
            fullWidth
            placeholder="Amount (USD)"
            inputRef={register({
              required: "Required",
              maxLength: 18,
              pattern: {
                value: isCurrency,
                message: invalidCurrencyMessage,
              },
            })}
            name={`${id}.amount`}
            size="small"
            variant="outlined"
            defaultValue={check.amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            error={errors?.[id] && errors?.[id].amount}
            helperText={
              errors?.[id] && errors?.[id].amount && errors?.[id].amount.message
            }
            FormHelperTextProps={{
              margin: "dense",
            }}
          />
        </BandedTableCellLessPadding>
        <BandedTableCellLessPadding>
          <TableTextField
            fullWidth
            placeholder="Payor"
            name={`${id}.payor`}
            inputRef={register({
              required: "Required",
              maxLength: 50,
              pattern: {
                value: isAlphanumeric,
                message: invalidAlphanumeric,
              },
            })}
            size="small"
            variant="outlined"
            defaultValue={check.payor}
            onChange={(e) => {
              setPayor(e.target.value);
            }}
            error={errors?.[id] && errors?.[id].payor}
            helperText={
              errors?.[id] && errors?.[id].payor && errors?.[id].payor.message
            }
            FormHelperTextProps={{
              margin: "dense",
            }}
          />
        </BandedTableCellLessPadding>
        <BandedTableCellLessPadding>
          <TableTextField
            fullWidth
            placeholder="Check No."
            name={`${id}.number`}
            inputRef={register({
              required: "Required",
              maxLength: 9,
              max: 999999999,
              pattern: {
                value: isNumber,
                message: invalidNumberMessage,
              },
              valueAsNumber: true,
            })}
            size="small"
            variant="outlined"
            defaultValue={check.number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            error={errors?.[id] && errors?.[id].number}
            helperText={
              errors?.[id] && errors?.[id].number && errors?.[id].number.message
            }
            FormHelperTextProps={{
              margin: "dense",
            }}
          />
        </BandedTableCellLessPadding>
        <BandedTableCellLessPadding>
          <Controller
            as={
              <AccountSelect
                formControlProps={{
                  error: errors?.[id] && errors?.[id].accountNumber,
                  fullWidth: true,
                }}
                helperText={
                  errors?.[id] &&
                  errors?.[id].accountNumber &&
                  errors?.[id].accountNumber.message
                }
                selectProps={{
                  placeholder: "Deposit To",
                  onChange: (e) => {
                    setValue(`${id}.accountNumber`, e.target.value);
                    setAccount(e.target.value);
                  },
                }}
              />
            }
            rules={{
              required: "Required",
            }}
            name={`${id}.accountNumber`}
            control={control}
            defaultValue={check.accountNumber ? check.accountNumber : ""}
          />
        </BandedTableCellLessPadding>
        <BandedTableCellLessPadding align="center" style={{ padding: 1 }}>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => remove(index)}
              size="small"
              style={{ color: theme.palette.error.main }}
              aria-label="Delete"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </BandedTableCellLessPadding>
        <BandedTableCellLessPadding align="center" style={{ padding: 1 }}>
          <Tooltip title="View Images">
            <IconButton
              size="small"
              color="primary"
              onClick={() => toggleCheck()}
            >
              <ArrowDropDownCircle
                style={{
                  transform: open ? "rotate(180deg)" : "",
                }}
              />
            </IconButton>
          </Tooltip>
        </BandedTableCellLessPadding>
      </BandedTableRow>
      <CheckDisplay open={open} front={check.front} back={check.back} />
    </>
  );
};

export default CheckListItem;
