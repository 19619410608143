import {
  createStyles,
  Select,
  TextField,
  Theme,
  withStyles,
} from "@material-ui/core";

export const TableTextField = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-input": {
        fontSize: theme.typography.pxToRem(12),
      },
      "& p": {
        fontSize: theme.typography.pxToRem(10),
      },
    },
  }),
)(TextField);

export const TableSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(12),
      padding: theme.spacing(2),
    },
  }),
)(Select);
