import {
  createStyles,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import { FontSettings, space } from "../../constants/brand";

const BandedTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),
      paddingTop: theme.spacing(3),
      border: "none",
    },
    body: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: FontSettings.fontWeights.medium,
      paddingTop: space[2],
      paddingBottom: space[2],
      border: "none",
    },
  }),
)(TableCell);

const BandedTableCellLessPadding = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),
      paddingRight: 0,
      border: "none",
    },
    body: {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: FontSettings.fontWeights.medium,
      paddingRight: 0,
      border: "none",
    },
  }),
)(TableCell);

const BandedTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.active,
      },
    },
  }),
)(TableRow);

const BandedTableRowAlt = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.active,
      },
    },
  }),
)(TableRow);

export {
  BandedTableCell,
  BandedTableCellLessPadding,
  BandedTableRow,
  BandedTableRowAlt,
};
