import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import { theme } from "../../constants/theme";

const PrimaryButton = ({ ...props }: ButtonProps) => (
  <Button color="secondary" disableElevation variant="contained" {...props} />
);

const CancelButton = ({ ...props }: ButtonProps) => (
  <Button
    style={{
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    }}
    variant="outlined"
    {...props}
  />
);

const OutlinedButton = ({ ...props }: ButtonProps) => (
  <Button
    variant="outlined"
    {...props}
  />
);



export {
  CancelButton,
  OutlinedButton,
  PrimaryButton,
  
};
