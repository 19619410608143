import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { theme } from "../../constants/theme";

interface SideBarItemProps {
  title: string;
  path: string;
}

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 0,
    color: theme.palette.text.disabled,
    height: 50,
    padding: theme.spacing(3),
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-end",
  },
});

const SideBarItem = ({ title, path }: SideBarItemProps) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      component={NavLink}
      to={path}
      activeStyle={{
        backgroundColor: theme.palette.action.active,
        color: theme.palette.primary.main,
      }}
    >
      {title}
    </Button>
  );
};

export default SideBarItem;
