import {
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { QueryResponse } from "react-fetching-library";
import Moment from "react-moment";
import { theme } from "../../constants/theme";
import { Batch } from "../../validations/entities";
import {
  BandedTableCell,
  BandedTableCellLessPadding,
  BandedTableRow,
} from "../brand/Tables";
var numeral = require("numeral");

interface BatchListProps {
  loading: boolean;
  error: boolean;
  onReload: () => Promise<QueryResponse<Batch[]>>;
  batches?: Batch[];
}

const BatchList = ({ loading, error, batches, onReload }: BatchListProps) => {
  return (
    <>
      <Box
        bgcolor="info.light"
        border={1}
        style={{
          borderColor: theme.palette.divider,
          borderBottomColor: "transparent",
          borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
        }}
        display="flex"
        justifyContent="flex-start"
        py={2}
        px={3}
        width={1}
      >
        <Typography variant="overline" color="secondary">
          Recent Batches
        </Typography>
      </Box>
      <Box
        border={1}
        style={{
          borderColor: theme.palette.divider,
          borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px `,
        }}
        width={1}
        display="flex"
        flexDirection="column"
        pb={3}
      >
        {loading && <LinearProgress color="secondary" />}
        {error && (
          <>
            <Typography>There was a problem getting the batches.</Typography>
            <Button color="secondary" onClick={onReload}>
              Retry
            </Button>
          </>
        )}
        {!loading && !error && batches && (
          <TableContainer>
            {batches.length > 0 ? (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <BandedTableCellLessPadding>
                      Batch ID
                    </BandedTableCellLessPadding>
                    <BandedTableCellLessPadding>
                      Date
                    </BandedTableCellLessPadding>
                    <BandedTableCellLessPadding>
                      Batch Name
                    </BandedTableCellLessPadding>
                    <BandedTableCellLessPadding>
                      Total Amount
                    </BandedTableCellLessPadding>
                    <BandedTableCellLessPadding>
                      No. of Checks
                    </BandedTableCellLessPadding>
                    <BandedTableCellLessPadding>
                      Status
                    </BandedTableCellLessPadding>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches.map((batch, index) => (
                    <BandedTableRow key={`batch-${index}`}>
                      <BandedTableCell>{batch.batchId}</BandedTableCell>
                      <BandedTableCell>
                        <Moment format="DD MMM yyyy">{batch.createdOn}</Moment>
                      </BandedTableCell>
                      <BandedTableCell>{batch.batchName}</BandedTableCell>
                      <BandedTableCell>
                        {numeral(batch.amount).format("$0,0.00")}
                      </BandedTableCell>
                      <BandedTableCell>{batch.noOfChecks}</BandedTableCell>
                      <BandedTableCell
                        style={{
                          color:
                            batch.status === "Processing"
                              ? theme.palette.primary.main
                              : "#009f95",
                        }}
                      >
                        {batch.status}
                      </BandedTableCell>
                    </BandedTableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box width={1} p={3}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  No pending or deposited batches.
                </Typography>
              </Box>
            )}
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default BatchList;
