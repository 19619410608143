import { Box } from "@material-ui/core";
import React from "react";
import { RouteProps } from "react-router-dom";
import { SideBarItem } from ".";
import { theme } from "../../constants/theme";

interface SideBarProps {
  routesList: { title?: string; route: RouteProps }[];
}

const SideBar = ({ routesList }: SideBarProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={1}
      height={1}
      borderRight={`1px solid ${theme.palette.divider}`}
    >
      {routesList.map((item) => (
        <>
          {item.title && item.route.path && (
            <SideBarItem title={item.title} path={item.route.path as string} />
          )}
        </>
      ))}
    </Box>
  );
};

export default SideBar;
