import { Action } from "react-fetching-library";
import {
  Account,
  AccountPost,
  Batch,
  BatchPost,
  Customer,
  CustomerPost,
  CustomerPostSuccess,
  Scanner,
  ScannerPost,
} from "../../validations/entities";
import ApiConfig from "../config";
const queryString = require("query-string");
const endpoints = ApiConfig.endpoints.CPAService;

/* GET Endpoints */
const getCustomers = (): Action => ({
  method: "GET",
  endpoint: endpoints.getCustomers,
  config: { skipAuth: false },
});

const getCustomerById = (params: { CustomerId: string }): Action<Customer> => ({
  method: "GET",
  endpoint: endpoints.getCustomerById.concat(
    "?",
    queryString.stringify(params),
  ),
  config: { skipAuth: false },
});

const getAccounts = (): Action<Account[]> => ({
  method: "GET",
  endpoint: endpoints.getAccounts,
  config: { skipAuth: false },
});

const getAccountById = (params: { AccountId: string }): Action<Account> => ({
  method: "GET",
  endpoint: endpoints.getAccountById.concat("?", queryString.stringify(params)),
  config: { skipAuth: false },
});

const getBatches = (): Action<Batch[]> => ({
  method: "GET",
  endpoint: endpoints.getBatches,
  config: { skipAuth: false },
});

const getBatchById = (): Action<Batch> => ({
  method: "GET",
  endpoint: endpoints.getBatches,
  config: { skipAuth: false },
});

const getBatchesByCustomerId = (params: {
  CustomerId: string;
}): Action<Batch[]> => ({
  method: "GET",
  endpoint: endpoints.getBatchesByCustomerId.concat(
    "?",
    queryString.stringify(params),
  ),
  config: { skipAuth: false },
});

const getRoleForObjectId = (params: { ObjectId: string }): Action<string> => ({
  method: "GET",
  endpoint: endpoints.getRoleForObjectId.concat(
    "?",
    queryString.stringify(params),
  ),
  config: { skipAuth: false },
});

const getScanners = (): Action<Scanner[]> => ({
  method: "GET",
  endpoint: endpoints.getScanners,
  config: { skipAuth: false },
});

const getScannerById = (params: { ScannerId: string }): Action<Scanner> => ({
  method: "GET",
  endpoint: endpoints.getScannerById.concat("?", queryString.stringify(params)),
  config: { skipAuth: false },
});

export {
  getCustomers as getCustomersAction,
  getCustomerById as getCustomerByIdAction,
  getAccounts as getAccountsAction,
  getAccountById as getAccountByIdAction,
  getBatches as getBatchesAction,
  getBatchById as getBatchByIdAction,
  getBatchesByCustomerId as getBatchesByCustomerIdAction,
  getRoleForObjectId as getRoleForObjectIdAction,
  getScanners as getScannersAction,
  getScannerById as getScannerByIdAction,
};

/* POST Endpoints */

const addCustomer = (body: CustomerPost): Action<CustomerPostSuccess> => ({
  method: "POST",
  endpoint: endpoints.addCustomer,
  body: body,
  config: { skipAuth: false },
});

const updateCustomer = (body: Omit<CustomerPost, "ObjectId">): Action => ({
  method: "POST",
  endpoint: endpoints.updateCustomer,
  body: body,
  config: { skipAuth: false },
});

const deleteCustomer = (body: { CustomerId: string }): Action => ({
  method: "POST",
  endpoint: endpoints.deleteCustomer,
  body: body,
  config: { skipAuth: false },
});

const addScanner = (body: ScannerPost): Action<Scanner> => ({
  method: "POST",
  endpoint: endpoints.addScanner,
  body: body,
  config: { skipAuth: false },
});

const updateScanner = (body: ScannerPost): Action<Scanner> => ({
  method: "POST",
  endpoint: endpoints.updateScanner,
  body: body,
  config: { skipAuth: false },
});

const deleteScanner = (body: { ScannerId: string }): Action<string> => ({
  method: "POST",
  endpoint: endpoints.deleteScanner,
  body: body,
  config: { skipAuth: false },
});

const addAccount = (body: AccountPost): Action<Account> => ({
  method: "POST",
  endpoint: endpoints.addAccount,
  body: body,
  config: { skipAuth: false },
});

const updateAccount = (body: AccountPost): Action<Account> => ({
  method: "POST",
  endpoint: endpoints.updateAccount,
  body: body,
  config: { skipAuth: false },
});

const deleteAccount = (body: { AccountId: string }): Action<string> => ({
  method: "POST",
  endpoint: endpoints.deleteAccount,
});

const uploadCheck = (formData: any): Action<string> => ({
  method: "POST",
  endpoint: endpoints.uploadCheck,
  body: formData,
  config: { skipAuth: false },
});

const addBatch = (body: BatchPost): Action<string> => ({
  method: "POST",
  endpoint: endpoints.addBatch,
  body: body,
  config: { skipAuth: false },
});

export {
  addCustomer as addCustomerAction,
  updateCustomer as updateCustomerAction,
  deleteCustomer as deleteCustomerAction,
  addScanner as addScannerAction,
  updateScanner as updateScannerAction,
  deleteScanner as deleteScannerAction,
  addAccount as addAccountAction,
  updateAccount as updateAccountAction,
  deleteAccount as deleteAccountAction,
  uploadCheck as uploadCheckAction,
  addBatch as addBatchAction,
};
